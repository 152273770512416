  <div class="col-12 col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 mb-3">
  <div class="row">
    <div class="col-6">
      <nav class="nav col-12" role="navigation">
          <li class="nav-item">
            <a
              [routerLink]="['/', 'legal', 'privacy-policy']"
              routerLinkActive="active"
              class="nav-link"
            >
              Privacy Policy
            </a>
          </li>
          <li class="nav-item">
            <a
              [routerLink]="['/', 'legal', 'terms-conditions']"
              routerLinkActive="active"
              class="nav-link"
            >
              Terms & Conditions
            </a>
          </li>
          <li class="nav-item">
            <a
              [routerLink]="['/', 'legal', 'cookies-policy']"
              routerLinkActive="active"
              class="nav-link"
            >
              Cookies Policy
            </a>
          </li>
      </nav>
    </div>
    <div class="col-6">
      <li class="nav-item">
        <a
          [routerLink]="['/', 'about']"
          routerLinkActive="active"
          class="nav-link"
        >
          About
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/', 'contact']"
          routerLinkActive="active"
          class="nav-link"
        >
          Contact
        </a>
      </li>
    </div>
  </div>
</div>
