<!-- <div class="row">
  <span class="currency text-center">
    <span class="my-auto currency-symbol">
      <span *ngIf="currency == 'gbp'" (click)="onCurrency('gbp')" class="fi fi-gb my-auto currency-symbol"></span>
      <span *ngIf="currency == 'usd'" (click)="onCurrency('usd')" class="fi fi-us my-auto currency-symbol"></span>
      <span *ngIf="currency == 'eur'" (click)="onCurrency('eur')" class="fi fi-eu my-auto currency-symbol"></span>
  </span>
  </span>
</div> -->
<mat-toolbar>

      <a routerLink="/"
          class="nav nav-item nav-fill justify-content-center">
            <img
            [src]="brandMainLogo"
            alt="LYRICAL DAYS"
            class="img img-fluid logo vertical-center"/>
        </a>
<!-- <span class="menu-spacer"></span> -->
        <span class="text-right float-right ml-auto">

          <button *ngIf="isAdmin && isAuth"  mat-icon-button type="button" class="neu-circle-button" aria-label="Account" (click)="onNoOcc()" routerLink="/admin">
            <mat-icon class="account my-auto">admin_panel_settings</mat-icon>
          </button>

        <button mat-icon-button type="button" class="neu-circle-button" aria-label="Account" (click)="onNoOcc()" routerLink="/account">

                    <mat-icon class="account my-auto">person</mat-icon>

        </button>

        <button mat-icon-button type="button" class="neu-circle-button" aria-label="Basket" (click)="onNoOcc()" routerLink="/basket">
          <mat-icon class="basket my-auto">shopping_basket</mat-icon>
        </button>
        <span 
        *ngIf="itemsInBasket()" 
        class="basket-counter">
          <div class="middle">
            {{ itemCount }}
          </div>
      </span>
    </span>
      <!-- <div class="float-right">
         <button *ngIf="isAdmin && isAuth" type="button" class="btn btn-primary m-1 d-sm-none d-md-none" routerLink="/admin">admin</button>
        <button *ngIf="!isAuth" type="button" class="btn btn-primary m-1 d-none d-sm-none d-md-none"  routerLink="/login">Login</button>
        <button *ngIf="!isAuth" type="button" class="btn btn-secondary m-1 d-none d-sm-none d-md-none" routerLink="/signup">Sign Up</button>
        <button *ngIf="isAuth" type="button" class="btn btn-secondary m-1 d-none d-sm-none d-md-none" routerLink="/my-account">My Account</button>
      </div> -->

</mat-toolbar>

<!-- <nav class="nav navbar nav-fill header" role="navigation">
   <div class="w-100 nav-fill">
    <div class="justify-content-center">
        <a routerLink="/"
          class="nav nav-item nav-fill justify-content-center">
            <img
            [src]="brandMainLogo"
            alt="LYRICAL DAYS"
            class="figure-img img-fluid"/>
        </a>
      </div>
    </div>
  
  <div class="w-100 menu-menu">
      <ul class="nav nav-fill nav-justified">
        <a 
          routerLink="/ecards"
          routerLinkActive="active"
          class="nav-item nav-link">
            <li class="navbar-text nav-item">
              <mat-icon>shop</mat-icon>
              <br>
            <span class="glyphicon glyphicon-film" aria-hidden="true" aria-label="e-card"></span>
            E-Cards 
            </li>
        </a>
        <a 
          routerLink="/basket"
          routerLinkActive="active"
          class="nav-item nav-link">
            <li class="navbar-text nav-item">
              <mat-icon>shopping_basket</mat-icon>
              <br>
            <span class="glyphicon glyphicon-shopping-cart" aria-hidden="true" aria-label="cart"></span>
            Basket
            <p *ngIf="itemsInBasket()">({{ itemCount }})</p>
            </li>
          </a>
        </ul>
    </div>
</nav> -->

<div class="container">
<!-- <nav class="occasion-nav col-9 offset-3 offset-sm-3 col-sm-8 col-md-6 mx-auto ml-auto text-center" [ngClass]="{'col-xs-12': isValentines || isChristmas, 'offset-sm-2': isValentines || isChristmas, 'offset-md-4': isValentines || isChristmas, 'offset-sm-3': !isValentines || !isChristmas, 'offset-lg-4': isChristmas || isValentines, 'offset-xl-4': isChristmas || isValentines}"> -->
  <nav class="navbar-nav">
<div class="row justify-content-center">

  <div class="occ-button" (click)="birthdaySelected()" [ngClass]="{'occ-button-selected': birthdayActive}">
  <button mat-icon-button type="button" class="birthday occasions" aria-label="Birthday ecards">
    <mat-icon class="mx-auto my-auto mat-icon-birthday birthday-icon">cake</mat-icon>
  </button>
  <p class="occ-name text-center">birthday</p>
  </div>

  <div class="occ-button" (click)="anniversarySelected()" [ngClass]="{'occ-button-selected': anniversaryActive}">
    <button mat-icon-button type="button" class="anniversary occasions" aria-label="Anniversary ecards">
      <picture>
        <source srcset="../../../assets/icons/anniversary_icon.svg" media="(min-width: 8px, min-height: 100%, color: #ffffff)">
        <img src="../../../assets/icons/anniversary_icon.svg" alt="thank you ecards" class="img img-fluid icon mb-5">
      </picture>
      <!-- <mat-icon class="my-auto mx-auto birthday-icon">cake</mat-icon> -->
    </button>
    <p class="occ-name text-center">anniversary</p>
    </div>

    <div class="occ-button" (click)="thankyouSelected()" [ngClass]="{'occ-button-selected': thankyouActive}">
      <button mat-icon-button type="button" class="thank-you occasions" aria-label="Thank you ecards">
        <picture>
          <source srcset="../../../assets/icons/thank_you_icon.svg" media="(min-width: 8px, min-height: 100%, color: #ffffff)">
          <img src="../../../assets/icons/thank_you_icon.svg" alt="thank you ecards" class="img img-fluid icon">
        </picture>
        <!-- <mat-icon class="my-auto mx-auto birthday-icon">cake</mat-icon> -->
      </button>
      <p class="occ-name text-center">thank you</p>
      </div>
  
      <div *ngIf="isValentines" class="occ-button" (click)="valentinesSelected()" [ngClass]="{'occ-button-selected': valentinesActive}">
        <button mat-icon-button type="button" class="valentines occasions" aria-label="Valentines ecards">
          <mat-icon class="my-auto mx-auto mat-icon-valentines birthday-icon">favorite</mat-icon>
        </button>
        <p class="occ-name text-center">valentines</p>
        </div>

        <div *ngIf="isChristmas" class="occ-button" (click)="christmasSelected()" [ngClass]="{'occ-button-selected': christmasActive}">
          <button mat-icon-button type="button" class="christmas occasions" aria-label="Christmas ecards">
            <mat-icon class="my-auto mx-auto mat-icon-valentines birthday-icon">star</mat-icon>
          </button>
          <p class="occ-name text-center">Christmas</p>
          </div>
          
          <!-- <div *ngIf="isLunar" class="occ-button" (click)="lunarSelected()" [ngClass]="{'occ-button-selected': lunarActive}">
            <button mat-icon-button type="button" class="lunar occasions" aria-label="Lunar New Year ecards">
              <mat-icon class="my-auto mx-auto mat-icon-valentines birthday-icon">nightlight</mat-icon>
            </button>
            <p class="occ-name text-center">Lunar New Year</p>
            </div> -->

  </div>
</nav>
</div>