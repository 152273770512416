<div class="container pt-5">
<section *ngIf="basketTotalItemsCount < 1" class="min-height">

  <div class="info text-center">
    <h5 class="text-center">Your basket is empty.</h5>
  </div>

</section>

<section class="min-height">
  <div *ngIf="basketTotalItemsCount > 0">

  <h3 class="mb-5">Your basket: </h3>

  <app-basket-list></app-basket-list>

  <div class="offset-xs-10 col-xs-2">
      <h6>subtotal ({{basketTotalItemsCount }} items) :</h6>
      <p *ngIf="subTotal > 0">
          {{ subTotal  | currency:currency.toUpperCase():'symbol' }}
      </p>
    </div>

  <div class="row">
    <div class="col-xs-2 offset-xs-10 col-sm-2 offset-sm-10">
      <button
      *ngIf="!isAuth"
      type="button" 
      class="btn btn-primary float-right float-sm-right mb-5"
      [disabled]="basketTotalItemsCount === 0 ? true : null"
      [routerLink]="['/login']"
      >
      Login & Proceed
      </button>
      <button 
      *ngIf="isAuth"
      type="button" 
      class="btn btn-primary float-right float-sm-right mb-5"
      [disabled]="basketTotalItemsCount === 0 ? true : null"
      (click)="onCheckOut()"
      [routerLink]="['/checkout/customise-ecards']"
      >
      Checkout
      </button>
    </div>
  </div>

</div>
</section>

</div>