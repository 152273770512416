    <h3>Welcome, Admin!</h3>

    <div class="jumbotron jumbotron-fluid notice text-white">
            <div class="container">
              <h1 class="display-4">We have work to do, today. Are you ready?</h1>
              <p class="lead">Its' a beautiful spring day. Let's get this working! Wahooo! xx</p>
            </div>
          </div>
    <hr>
    <h4>Shop Management</h4>
    
    <button routerLink="shop-management/tax"
    role="button" 
    class="btn btn-shop-m">
    <img src="../assets/brand/lyrical-days-icons_TAX.png" 
    alt="TAX" class="img-fluid">
    <br>
    <p>TAX</p>
    </button>
    
    <button routerLink="shop-management/shipping"
    role="button" 
    class="btn btn-shop-m">
    <img src="../assets/brand/lyrical-days-icons_Shipping.png" 
    alt="SHIPPING" class="img-fluid">
    <br>
    <p>SHIPPING</p>
    </button>
    
    <button routerLink="shop-management/coupons"
    role="button" 
    class="btn btn-shop-m">
    <img src="../assets/brand/lyrical-days-icons_Coupons.png" 
    alt="COUPONS" class="img-fluid">
    <br>
    <p>COUPONS & VOUCHERS</p> 
    </button>
    
    
    <button routerLink="shop-management/email-templates"
    role="button" 
    class="btn btn-shop-m">
    <img src="../assets/brand/lyrical-days-icons_Email Notifications.png" 
    alt="EMAIL NOTIFACTIONS" class="img-fluid">
    <br>
    <p>EMAIL NOTIFACTION templates</p> 
    </button>
    
    <button routerLink="shop-management/reports"
    role="button" 
    class="btn btn-shop-m">
    <img src="../assets/brand/lyrical-days-icons_Reports.png" 
    alt="REPORTS" class="img-fluid">
    <br>
    REPORTS
    </button>
    
    
    
    <!-- <a
    [routerLink]="[/shop-management/tax]"
    >
    
    </a>
    <a
    [routerLink]="[/shop-management/tax]"
    >
    
    </a> -->
    
    
    
    
    
    <!-- BREAK SECTION -->
    <hr>
    <h4>Product Management</h4>
    <button routerLink="product-management/products"
    role="button" 
    class="btn btn-product-m">
    <p>Product Directory</p>
    </button>
    <button 
    role="button" routerLink="product-management/orders"
    class="btn btn-product-m">
    <p>Order Directory</p>
    </button>
    <button routerLink="product-management/manage-occ-cat-tag"
    role="button" 
    class="btn btn-product-m">
    <p>Manage Occasions, Cateogries & Tags</p>
    </button>
    
    
    <!-- BREAK SECTION -->
    <hr>
    <h4>Human Resources</h4>
    <!-- BREAK SECTION -->
    <hr>
    <h4>Team Work</h4>
    <!-- BREAK SECTION -->
    <hr>
    <h4>Customer Support</h4>
    <button 
    role="button" 
    class="btn btn-primary col">
    Help Desk
    </button>
    <button routerLink="customer-support/users"
    role="button" 
    class="btn btn-primary col">
    Users
    </button>
    <br>
        
