
<ul class="nav nav-tabs">
    <a routerLink="/login"
    routerLinkActive="active"
    class="nav-link nav-fill nav-link-active" 
    style="width: 50%; cursor: pointer; border-radius: 0px;">
    <li class="nav-item">Log in</li></a>
    <a routerLink="/signup"
    disabled
    routerLinkActive="active"
    class="nav-link nav-fill nav-tabs-not-active" 
    style="width: 50%; cursor: pointer; border-radius: 0px;">
    <li class="nav-item">Create Account</li></a>
</ul>


<!-- <div class="container">
    <section *ngIf="isAttemptingLogin">
        <mat-spinner></mat-spinner>
    </section>

<div *ngIf="!isAttemptingLogin">
    <form  fxLayout="column"  fxLayoutAlign="center center" [formGroup]="logInForm" (ngSubmit)="onLogIn()">
    <div formGroupName="user">

   <div class="form-group">

      <label for="email">Email Address</label> 
      <input 
      type="email" 
      id="email" 
      name="email" 
      formControlName="email" 
      class="form-control" 
      size="50">
      <mat-hint  *ngIf="!confirmReset">
        <button mat-button (click)="onForgotPassword()" class="help float-right" type="button"> 
        Forgot Password </button>
      </mat-hint>    

      <div class="mt-3">
       <button *ngIf="confirmReset" 
       (click)="onResetPassword()" 
       class="btn btn-primary float-right" 
       type="button" 
       [disabled]="!logInForm.get('user.email').valid "> Reset Password </button>
      <button *ngIf="isResettingPassword" 
      class="btn btn-warning" 
      (click)="onCancelReset()" 
      type="button"> Cancel</button>
    </div>
      
    <br>

      <mat-hint *ngIf="emailSent" class="form-text mt-3">A reset password email is on its way.</mat-hint>
      <mat-hint *ngIf="emailNeeded" class="form-text mt-3 text-danger">Please enter your email address, then try "reset password" again.</mat-hint>
   
<br>
      <span 
      *ngIf="!logInForm.get('user.email').valid && logInForm.get('user.email').touched"
      class="form-text text-danger">   <br>
      Please enter a valid email adress.
    </span>


      <span *ngIf="!isResettingPassword">
      <label for="password">Password</label>
      <input type="password" id="password" name="password" formControlName="password" class="form-control" >
      <span 
      *ngIf="!logInForm.get('user.password').valid && logInForm.get('user.password').touched"
      class="form-text text-danger">
      <mat-hint class="form-text text-danger">Please enter a valid password.</mat-hint>
    <mat-hint *ngIf="logInForm.get('user.password').value?.length < 7">{{ logInForm.get('user.password').value?.length }} / 6</mat-hint>
    </span>
</span>
    </div>



<button 
fxLayoutAlign="center"
*ngIf="!isResettingPassword"

class="btn btn-primary text-center" 
type="submit" 
[disabled]="!logInForm.valid">
Log In
</button>

<br>
<mat-hint *ngIf="resetError" class="form-text text-danger">{{ resetError }}</mat-hint>
<mat-hint *ngIf="ifLogInError" class="form-text text-danger whitespace">{{ logInError }}</mat-hint>
</div>
<mat-hint *ngIf="ifReqResetPwd" class="form-text whitespace">{{ confirmReqResetPasswordNotice }}</mat-hint>
</form>
</div>
</div> -->

<!-- old working css version -->

<div class="container mt-5">
  <section *ngIf="isAttemptingLogin">
    <mat-spinner></mat-spinner>
</section>
  <div *ngIf="!isAttemptingLogin" class="mt-5">
   
  <form  fxLayout="column"  fxLayoutAlign="space-evenly center" [formGroup]="logInForm" (ngSubmit)="onLogIn()">
    <h2 *ngIf="!emailSent">{{isResettingPassword ? 'Reset password' : 'Login'}}</h2>
    <h2 *ngIf="emailSent">Email sent</h2>
    <mat-hint *ngIf="isResettingPassword && !emailSent" class="mt-3 mb-5">We'll send an email to your account to reset your password.</mat-hint>
    <mat-hint *ngIf="emailSent">Check your email for your reset password link to continue.</mat-hint>
  <div formGroupName="user">

    <div class="form-group">

        <label *ngIf="!emailSent" for="email">Email Address</label> 
        <input *ngIf="!emailSent" type="email" id="email" name="email" formControlName="email" class="form-control" size="50">

        <!-- <span 
        *ngIf="!logInForm.get('user.email').valid && logInForm.get('user.email').touched"
        class="form-text text-danger mb-3">Please enter a valid email adress.</span> -->


        <!-- enter password -->
        <div *ngIf="!isResettingPassword">
            <label for="password">Password</label>
              <input type="password" id="password" name="password" formControlName="password" class="form-control" >
              <span 
              *ngIf="!logInForm.get('user.password').valid && logInForm.get('user.password').touched"
              class="form-text text-danger">
              <!-- <mat-hint class="form-text text-danger">Please enter a valid password.</mat-hint> -->
            <!-- <mat-hint *ngIf="logInForm.get('user.password').value?.length < 7">{{ logInForm.get('user.password').value?.length }} / 6</mat-hint> -->
            </span>
        </div>
        <mat-hint  *ngIf="!confirmReset">
          <button mat-button (click)="onForgotPassword()" class="help float-right mb-5" type="button"> 
          Forgot Password </button>
        </mat-hint>   
        
        <div  *ngIf="confirmReset" class="mt-3" fxLayout="column">
          <button
          *ngIf="!emailSent"
          (click)="onResetPassword()" 
          class="btn btn-primary float-right" 
          type="button" 
          > Send reset password link to email </button>

       </div>
      
        
        <!-- <mat-hint *ngIf="emailSent" class="form-text">A reset password email is on its way.</mat-hint> -->
        <mat-hint *ngIf="emailNeeded" class="form-text text-danger text-wrap">Please enter the email address associated with your<br> account to reset your password.</mat-hint>
        <hr  *ngIf="isResettingPassword">
        <button *ngIf="isResettingPassword" 
        mat-button
        fxLayout="row"
        class="btn help mx-auto mt-3" 
        (click)="onCancelReset()" 
        type="button"><mat-icon>arrow_back</mat-icon> Back to login</button>
      </div>
 
  </div>




<button 
*ngIf="!isResettingPassword"
fxLayoutAlign="center"
class="btn btn-primary pl-5 pr-5 rounded-pill" 
type="submit" 
[disabled]="!logInForm.valid"
>Log in</button>
<br>
<mat-hint *ngIf="resetError" class="form-text text-danger">{{ resetError }}</mat-hint>
<mat-hint *ngIf="ifLogInError" class="form-text text-danger whitespace">{{ logInError }}</mat-hint>
<mat-hint *ngIf="ifReqResetPwd" class="form-text whitespace">{{ confirmReqResetPasswordNotice }}</mat-hint>
</form>

</div>

</div>