<div class="row align-items-center text-center">
  <div class="col-12 col-md-3">
    <span class="text-center extra-padding">
          <img 
    [src]="basketItem.productImage"
     alt="{{ basketItem.name }}"
     class="img-figure img-fluid"
     style="max-height: 200px">
    </span>
    </div>
       <div class="col-3 col-md-3">
         {{ basketItem.name }}
        </div>
        <div *ngIf="basketItem.type == 'ecard'" class="col-3 col-md-2">
          {{ basketItem.sendLimit * basketItem.quantity }}
          <br>
          <mat-hint>(Send limit)</mat-hint>
         </div>
       <div class="col-2 col-md-1">
         <div 
         class="btn-group dropdown" 
         appDropdown>
           <button 
           type="button" 
            class="btn btn-primary dropdown-toggle">
             {{ basketItem.quantity }}
             <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
            </button>
           <ul class="dropdown-menu">
             <li *ngFor="let quantity of quantitySelector" (click)="updateQuantity(basketItem, quantity)"><a class="dropdown-item">{{quantity}}</a></li>
           </ul>
          </div>
      </div>
     <div *ngIf="currency =='gbp'" class="col-2 col-md-2">
       {{ (basketItem.price * basketItem.quantity)| currency:currency.toUpperCase():'symbol' }}
      </div>
      <div *ngIf="currency =='eur'" class="col-2 col-md-2">
        {{ (basketItem.priceEUR * basketItem.quantity)| currency:currency.toUpperCase():'symbol' }}
       </div>
       <div *ngIf="currency =='usd'" class="col-2 col-md-2">
        {{ (basketItem.priceUSD * basketItem.quantity)| currency:currency.toUpperCase():'symbol' }}
       </div>
      <div class="col-2 col-md-1">
        <span>
           <button 
     type="button" 
     class="btn btn-danger"
     (click)="onDelete(basketItem)">X</button>
        </span>
      </div>
</div>
<hr>