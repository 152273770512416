<div class="all">

        <div class="core">
            <app-header></app-header>
        </div> 

        <div class="main-container" id="container"> 
                
                <!-- <canvas #canvas id='canvas'></canvas> -->
                <router-outlet></router-outlet>
        </div>
        

        <div class="bottom">
                <app-footer></app-footer>
        </div>


</div>

       

   