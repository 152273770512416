<p>
    This is the e-cards directory. Here there will be a table that will be auto-populated with e-card data.
</p>
<div class="row">
  <div class="col-sm-pull-9">
    <button class="btn btn-primary m-2"
    routerLink="upload">
    Add New
  <span class="glyphicon glyphicon-plus" aria-hidden="true" aria-label="add"></span>
  </button>
  <button class="btn btn-primary m-2">
      Export
    <span class="glyphicon glyphicon-download-alt" aria-hidden="true" aria-label="export"></span>
    </button>
    <button class="btn btn-primary m-2">
       Import
      <span class="glyphicon glyphicon-cloud-upload" aria-hidden="true" aria-label="import"></span>
      </button>
  </div>
  
</div>
<br>
<app-e-cards-directory></app-e-cards-directory>
