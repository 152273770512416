<div class="text-center">
  <h2>Users</h2>
</div>

<hr>
<div class="row">
<div class="col-3 text-center">
    <h5> Username </h5>
</div>
<div class="col-3 text-center">
    <h5> Name </h5>
  </div>
  <div class="col-3 text-center">
      <h5> Email </h5>
    </div>
    <div class="col-3 text-center">
        <h5> Role </h5>
      </div>
</div>
<app-user-list></app-user-list>
