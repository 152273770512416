<div class="container">
  <h1>Conditions of Suppy Policy</h1>
  <p>
    <mat-hint>
      <i>Last Updated 17 August 2020 </i>
    </mat-hint>
  </p>
  <h5>Defintions</h5>
  <ul>
    <li>"Site" herein refers to the website and/or webapp LryicalDays.com</li>
    <li>"Ecards" means the electronic greetings cards available on the Site</li>
    <li>
      "Content" means any media such as but not limited to animations,
      illustrations, paintings, graphics, images, photographs and text on the
      Site, including any ecards or other digital products
    </li>
    <li>
      "User" means a visitor to the site who has created an account on the Site
    </li>
    <li>
      "Digital Product" means a digital product on the Site that is available
      for purchase or as a free add-on to a purchase
    </li>
    <li>
      "You" means any person who visits the site but is not limited to User,
      Your shall be construed accordingly
    </li>
  </ul>
  <ol>
    <li>
      <h5>
        Introduction
      </h5>
      <p>
        These terms and conditions set out the terms and conditions between you,
        the User, and LryicalDays.com (“us”, “we”, "our", "site"), governing the
        use of our Site and our <b>ecards and ecard emailing service</b> therein
        (the “products”). Your use of our Site, and purchase, emailing and use
        of our products, constitutes your full acceptance of these terms and
        conditions. If you do not agree with these terms and conditions, you
        should not use our Site or purchase, email, send, stream or use any of
        our products.
      </p>
    </li>
    <li>
      <h5>
        License Grant
      </h5>
      <p>
        The Site grants you a limited licence to use, in accordance with this
        Conditons of Supply Policy as part of the Terms and Conditions, such
        Ecards and Digital Products, and other features of the Site as are from
        time to time available. No use of the Site, Content, Ecards, Digital
        Products is permitted save for what is outlined in the Terms of
        Conditions and any other Policy or Conditions that is included by
        reference therein.
      </p>
      <p>
        Your purchase of one of our products constitutes our granting to you of
        a non-exclusive, non-sublicensable, non-transferable license to use our
        products as within the limits of the Acceptable Use Policy as part of
        the Terms of Conditions, for the purpose of your own personal and
        private use, to send Ecards to persons who are known to you (the
        “purpose”). You agree that under no circumstances shall you use, or
        permit to be used, any product other than for the aforesaid purpose. For
        the avoidance of doubt, you shall not copy, recreate, imitate, re-sell,
        sublicense, rent out, share or otherwise distribute any of our products,
        whether modified or not, to any third party. You agree not to use any of
        our products in a way which might be detrimental to us or damage our
        reputation.
      </p>
    </li>
    <li>
      <h5>
        Intellectual Property
      </h5>
      <p>
        The products, whether modified or not, and all intellectual property and
        copyright contained therein, are and shall at all times remain our sole
        and exclusive property. You agree that under no circumstances, whether
        the product has been modified or not, shall you have or attempt to claim
        ownership of any intellectual property rights or copyright in the
        product.
      </p>
    </li>
    <li>
      <h5>
        Refunds and Chargebacks
      </h5>
      <p>
        Once a product has been purchased by you, no right of cancellation or
        refund exists under the Consumer Protection (Distance Selling)
        Regulations 2000 due to the electronic nature of our products. Any
        refunds shall be at our sole and absolute discretion. You agree that
        under no circumstances whatsoever shall you initiate any chargebacks via
        your payment provider. You agree that any payments made by you for any
        of our products are final and may not be charged back. We reserve the
        right to alter any of our prices from time to time.
      </p>
    </li>
    <li>
      <h5>
        Limitation of Liability
      </h5>
      <p>
        We are not responsible for any failure of Ecards sent through the Site
        to reach their intended recipients, or to reach such recipients on a
        specified date.
      </p>
    </li>
    <li>
      <h5>
        General
      </h5>
      <p>
        These terms and conditions constitute the entire agreement and
        understanding between you and us for the supply of our digital products,
        and shall supersede any prior agreements whether made in writing,
        orally, implied or otherwise. The failure by us to exercise or enforce
        any right(s) under these terms and conditions shall not be deemed to be
        a waiver of any such right(s) or operate so as to bar the exercise or
        enforcement thereof at any time(s) thereafter, as a waiver of another or
        constitute a continuing waiver. You agree that monetary damages may not
        be a sufficient remedy for the damage which may accrue to us by reason
        of your breach of these terms and conditions, therefore we shall be
        entitled to seek injunctive relief to enforce the obligations contained
        herein. The unenforceability of any single provision within these terms
        and conditions shall not affect any other provision hereof. These terms
        and conditions, your acceptance thereof, and our relationship with you
        shall be governed by English law. You and we both agree that the courts
        of England and Wales will have exclusive jurisdiction expect that if you
        are a resident of Northern Ireland you may also bring proceedings in
        Northern Ireland, and if you are resident of Scotland, you may also
        bring proceedings in Scotland. If you have any complaint or wish to
        raise a dispute under these Terms and Conditions or otherwise in
        relation to the Site please follow this link
        <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>
      </p>
      <p>
        Contacting Us
      </p>
      <p>
        Please do not hesitate to contact us regarding any matter relating to
        this Conditions of Supply Policy via email admin@lyricaldays.com
      </p>
    </li>
  </ol>
</div>
