

<ul class="nav nav-tabs">
    <a routerLink="/login"
    routerLinkActive="active"
    class="nav-link nav-fill" 
    style="width: 50%; cursor: pointer;">
    <li class="nav-item">Log in</li>
    </a>
    <a routerLink="/signup"
    routerLinkActive="active"
    class="nav-link nav-fill" 
    style="width: 50%; cursor: pointer;">
    <li class="nav-item">Create account</li></a>
</ul>

<div class="container col-12">    
  <section *ngIf="isAttemptingSignup">
      <mat-spinner></mat-spinner>
  </section>
  <div *ngIf="!isAttemptingSignup" class="mt-5 col-12">
  <form  fxLayout="column"  fxLayoutAlign="center center" class="col-12"  [formGroup]="signUpForm" (ngSubmit)="onSignUp()">
    <h2>Create account</h2>
  <div formGroupName="user">

   <div class="form-row">
      <label for="firstname">First name</label>
    <input 
    type="text" 
    id="firstname" 
    name="firstname" 
    formControlName="firstname" 
    class="form-control">
    <span 
    *ngIf="!signUpForm.get('user.firstname').valid && signUpForm.get('user.firstname').touched"
    class="form-text text-danger">Please enter your first name.</span>
   </div>
   
   <div class="form-row">
  <label for="surname">Last name</label>
    <input 
    type="text" 
    id="surname" 
    name="surname" 
    formControlName="surname" 
    class="form-control">
    <span 
    *ngIf="!signUpForm.get('user.surname').valid && signUpForm.get('user.surname').touched"
    class="form-text text-danger">Please enter your last name.</span>
   </div>
  
   <div class="form-row">
         <label for="email">Email Address</label>
    <input 
    type="email" 
    id="email" 
    name="email" 
    formControlName="email" 
    class="form-control">
    <span 
    *ngIf="!signUpForm.get('user.email').valid && signUpForm.get('user.email').touched"
    class="form-text text-danger">Please enter a valid email address.</span>
   </div>

   <div class="form-row">
         <label for="password">Password</label>
    <input 
    type="password" 
    id="password" 
    name="password" 
    formControlName="password" 
    class="form-control">
    <span 
    *ngIf="!signUpForm.get('user.password').valid && signUpForm.get('user.password').touched"
    class="form-text text-danger">Please enter a password at least 6 characters long.</span>
    <span class="float-right col-12 mt-1">
      <mat-hint class="offset-8 pl-2 pr-2" *ngIf="signUpForm.get('user.password').value?.length < 7" style="background-color: #f4f4f4; border-radius: 10px; color: #8c8c8c">{{ signUpForm.get('user.password').value?.length || '0' }} / 6 characters</mat-hint>
    </span>
   </div>

  <div class="form-row">
        <label for="password">Repeat Password</label>
    <input 
    type="password" 
    id="password-repeat" 
    name="password-repeat" 
    formControlName="password-repeat" 
    class="form-control">
    <span 
    *ngIf="signUpForm.get('user.password-repeat').touched && signUpForm.get('user.password-repeat').value != signUpForm.get('user.password').value"
    class="form-text text-danger">This password does not match.</span>
  </div>

<div class="form-row">
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="legal" formControlName="legal" required>
    <label class="legal" for="legal">I have read and agree to the <a class="tcs" [routerLink]="['/', 'legal', 'terms-conditions']">Terms and Conditions</a>.</label>
  </div>
</div>

<div *ngIf="isAvailable" class="form-group" formGroupName="roles">
  <h3>E-Card Subscription?</h3>
  <p>Get full access to all e-cards throughout the year by paying a small, one time fee.</p> 

  <label class="checkbox-inline">
      <input 
      type="checkbox" 
      data-toggle="false" 
      formControlName="subscriber"> Yes, please!
    </label>
</div>
<hr>

    <div *ngIf="signUpForm.get('user.roles.subscriber').value">
      <app-payment-details></app-payment-details>
  </div>
  </div>

  

  <button 
  class="btn btn-primary w-25"
  type="submit" 
  [disabled]="!signUpForm.valid || (signUpForm.get('user.password').value != signUpForm.get('user.password-repeat').value)">
  Sign up</button>
</form>
<hr>
<mat-hint *ngIf="ifSignUpError" class="form-text text-danger">{{ signUpError }}</mat-hint>
</div>
  
