
   <section *ngIf="mailer" [ngStyle]="{backgroundColor: mailer.ecard.styleOptions.backgroundBodyColor}" class=" text-center">
  <img [src]="whiteOutlineLogoPath" alt="Lyrical Days" class="img-fluid logoMax">
  <!-- <p>Pull ecard item as customised from folder bin ... as relevant</p>
  <p>ngClass="backgroundColourSpecified"</p> -->
  <mat-spinner *ngIf="!hasLoaded"></mat-spinner>
  <div class="col-12">
   
    <div class="row">
      <div 
      [ngClass]="{'col-md-4': mailer.ecard.styleOptions.aspectRatio == 'portrait', 
      'offset-md-4': mailer.ecard.styleOptions.aspectRatio == 'portrait', 
      'col-md-12': mailer.ecard.styleOptions.aspectRatio == 'landscape'}">
        <div class="text-center" *ngIf="mailer.ecard.formatOptions.mp4VideoEmail.downloadURL != undefined">
          <span *ngIf="hasLoaded" class="to">
            <h4 class="whitespace card-font-spec typewriter" [ngStyle]="{color: mailer.ecard.styleOptions.textColor, 'font-family': mailer.ecard.styleOptions.personageFont, 'text-shadow': mailer.ecard.styleOptions.textShadow}" >
              {{ mailer.to || mailer.contact.contactFirstName }}
            </h4>
          </span>
           <span *ngIf="hasLoaded" class="overlay whitespace">
          <h3 class="whitespace card-font-spec-bigger typewriter" [ngStyle]="{color: mailer.ecard.styleOptions.textColor, 'font-family': mailer.ecard.styleOptions.messageFont, 'text-shadow': mailer.ecard.styleOptions.textShadow}" >
            {{ mailer.message }}
          </h3>
        </span>
        <span *ngIf="hasLoaded" class="from">
          <h4 class="whitespace card-font-spec typewriter" [ngStyle]="{color: mailer.ecard.styleOptions.textColor, 'font-family': mailer.ecard.styleOptions.personageFont, 'text-shadow': mailer.ecard.styleOptions.textShadow}" >
            {{ mailer.signature }}
          </h4>
        </span>
          <video #videoPlayer  id="video" preload="auto" width="auto" height="auto" class="img-fluid" [ngClass]="{ 'maxportrait': mailer.ecard.styleOptions.aspectRatio == 'portrait', 
            'maxlandscape': mailer.ecard.styleOptions.aspectRatio == 'landscape'}" loop autoplay playsinline oncontextmenu="return false;">
                  <source [src]="mailer.ecard.formatOptions.mp4VideoEmail.downloadURL" type="video/mp4">
                  <!-- <source src="movie.ogg" type="video/ogg"> -->
                  Your browser does not support the video tag.
          </video>
        </div>
            <span *ngIf="!mailer.ecard.formatOptions.mp4VideoEmail.downloadURL">
              <!-- <h1>error</h1> -->
               <img [src]="mailer.ecard.thumbnail.downloadURL" alt="{{ mailer.ecard.mediaAltText }}"
        class="figure-img img-fluid">
            </span>
        </div>
    </div>
    </div>

</section>


<!--  [ngClass]="{
            'maxportrait': mailer.ecard.styleOptions.aspectRatio == 'portrait', 
            'maxlandscape': mailer.ecard.styleOptions.aspectRatio == 'landscape'}"
          from video class tag -->



          