<table class="table-bordered col-sm-12">
  <tr class="table">
    <th class="">Name</th>
    <th>ID/SKU</th>
    <th>Stock</th>
    <th>Price</th>
    <th>Categories</th>
    <th>Tags</th>
    <th>Featured</th>
    <th>Date Scheduled/Published</th>
    <th>Sats (Report)</th>
</tr>
<tr class="table" *ngFor="let ecard of ecards;">
  <td>{{ ecard.name }}</td>
  <td>{{ ecard.id }}</td>
  <td>{{ "stockLevel" }}</td>
  <td>{{ ecard.price | currency:'GBP':'symbol' }}</td>
  <td>{{ ecard.category }}</td>
  <td>{{ ecard.tag }}</td>
  <td>//Featured toggle</td>
  <td>{{ ecard.dateAdded  }} //Published or scheduled</td>
  <td>// Go to report on this ecard | gift</td>
</tr>
<tr class="table" *ngFor=" let gift of gifts;">
  <td>{{ gift.name }}</td>
  <td>{{ gift.id }}</td>
  <td>{{ "stockLevel" }}</td>
  <td>{{ gift.price | currency:'GBP':'symbol' }}</td>
  <td>{{ gift.category }}</td>
  <td>{{ gift.tag }}</td>
  <td>//Featured toggle</td>
  <td>{{ gift.dateAdded }} //Published or scheduled</td>
  <td>// Go to report on this ecard | gift</td>
</tr>
  
</table>
