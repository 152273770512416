<h3>{{ editMode ? 'Update E-Card' : 'Upload New E-Card' }}</h3>
 <form [formGroup]="formECard" (ngSubmit)="onSubmitECard()">

 <div class="row">
   
    <div class="col-md-6">
     <div class="form-group">
         <div formGroupName="newECard">
            <div class="form-row">
                    <label for="name" class="col-form-label">
                        Title
                    </label>
                    <input 
                    type="text"
                    name="name" 
                    id="name" 
                    formControlName="name" 
                    class="form-control">
            </div>

            <div class="form-row">
                    <label for="synopsis">
                        Synopsis
                    </label>
                    <textarea
                    name="synopsis" 
                    id="synopsis" 
                    formControlName="synopsis" 
                    class="form-control"
                    placeholder="In 3 sentences or less, sell me on this."
                    rows="3">
                    </textarea>
            </div>

            <!-- <div class="form-row">
                    <label for="thumbnail" class="col-form-label">Thumbnail</label>
                        <div class="input-group">
                                <label for="thumbnail" class="custom-file-label">
                                Set thumbnail
                                </label>
                                <button class="btn btn-primary">Set Thumbnail</button>
                                <input 
                                type="file" 
                                name="thumbnail" 
                                id="thumbnail" 
                                formControlName="thumbnail" 
                                class="custom-file-input"> 
                        </div>
                        <app-media-uploader></app-media-uploader>
                </div> -->

                <div class="form-row">
                    <div class="col-9">
                         <label for="thumbnail" class="col-form-label">
                             Thumbnail
                            </label>
                    </div>
                   <div class="col-3">
                        <button type="button" class="btn btn-primary" (click)="onPickThumbnail()">
                            <!-- <mat-icon>add_circle</mat-icon> -->
                            Set Thumbnail 
                        </button>
                   </div>
                </div>

                <div class="form-row">
                    <label for="name" class="col-form-label">
                        Media alt text
                    </label>
                    <input 
                    type="text"
                    name="alt" 
                    id="alt" 
                    formControlName="mediaAltText" 
                    class="form-control">
            </div>
            
                <div formGroupName="formatOptions" class="">
                    <label for="formatOptions" class="col-form-label">
                    Format Options
                </label>

                <div class="form-row">
                    <div class="col-9">
                        <span class="form-inline">MMS (9:16)</span>
                    </div>
                    <div class="col-2 mr-1">
                         <label class="switch">
                        <input 
                        type="checkbox" 
                        name="asEmail" 
                        id="asEmail" 
                        formControlName="asMMS" 
                        class="custom-control switch">
                        <span class="slider round"></span>
                        </label>
                    </div> 
                </div>
                    
                    <div formGroupName="uploadMMS" *ngIf="formECard.get('newECard.formatOptions.asMMS').value">
                        <div class="form-extra form-row">
                        <div class="form-row">
                            <label for="mp4VideoMMS" class="col-form-label">.mp4 Video file for MMS</label>
                            <div class="input-group">
                                <label for="mp4VideoMMS" class="custom-file-label">
                                    file.mp4
                                </label>
                                <input 
                                type="file" 
                                name="mp4VideoMMS" 
                                id="mp4VideoMMS" 
                                formControlName="mp4VideoMMS" 
                                class="custom-file-input">
                                </div>   
                        </div>
                
                        <div class="form-row">
                            <label for="oggVideoMMS" class="col-form-label">.ogg Video file for MMS</label>
                            <div class="input-group">
                                <label for="oggVideoMMS" class="custom-file-label">
                                    file.ogg
                                </label>
                                <input 
                                type="file" 
                                name="oggVideoMMS" 
                                id="oggVideoMMS" 
                                formControlName="oggVideoMMS" 
                                class="custom-file-input">
                            </div> 
                        </div>
                    </div>
                </div>

                    <div class="form-row">
                        <div class="col-9">
                            <span class="form-inline">EMAIL (16:9)</span>
                        </div>
                        <div class="col-2 mr-1">
                             <label class="switch">
                            <input 
                            type="checkbox" 
                            name="asEmail" 
                            id="asEmail" 
                            formControlName="asEmail" 
                            class="custom-control switch">
                            <span class="slider round"></span>
                            </label>
                        </div> 
                    </div>
                    
                    <div formGroupName="uploadEmail" *ngIf="formECard.get('newECard.formatOptions.asEmail').value">
                            <div class="form-extra form-row">    
                                <div class="form-row">
                                        <label for="mp4VideoEmail" class="col-form-label">.mp4 Video file for email</label>
                                        <div class="col-3">
                                            <button type="button" class="btn btn-primary" (click)="onPickEmailmp4()">
                                                <!-- <mat-icon>add_circle</mat-icon> -->
                                                Set email.mp4
                                            </button>
                                       </div>
                                </div>
                    
                                <div class="form-row">
                                        <label for="oggVideoEmail" class="col-form-label">.ogg Video file for email</label>
                                        <div class="input-group">
                                        <label for="oggVideoEmail" class="custom-file-label">
                                            file.ogg
                                        </label>
                                        <input 
                                        type="file" 
                                        name="oggVideoEmail" 
                                        id="oggVideoEmail" 
                                        formControlName="oggVideoEmail" 
                                        class="custom-file-input">
                                        </div> 
                                </div>
                            </div>
                        </div>    
            </div>

            <div class="form-row">
                    <label class="checkbox-inline">
                        <input type="checkbox"
                        data-toggle="true"
                        formControlName="aspectRatio">
                        {{ formECard.get('newECard.aspectRatio').value ? 'landscape' : 'portrait' }}
                    </label>
                </div>

            <div class="form-row">
                <label class="checkbox-inline">
                    <input type="checkbox"
                    data-toggle="true"
                    formControlName="textColor">
                    {{ formECard.get('newECard.textColor').value ? 'Text is Light' : 'Text is Dark' }}
                </label>
            </div>

            <div class="form-row">
                <label class="col-form-label">Set Personage Font</label>
             <div 
            class="btn-group dropdown" 
            appDropdown>
          
              <button 
              type="button" 
               class="btn btn-primary dropdown-toggle">
                {{ personageFont || fontArray[0] }}
                <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
               </button>
              <ul class="dropdown-menu">
                <li *ngFor="let font of fontArray" [ngStyle]="{'font-family': font}" (click)="updatePersonageFont(font)"><a class="dropdown-item">{{ font }}</a></li>
              </ul>
             </div>
            </div>

            <div class="form-row">
            <label class="col-form-label">Set Message Font</label>
             <div 
             class="btn-group dropdown" 
             appDropdown>
            
               <button 
               type="button" 
                class="btn btn-primary dropdown-toggle">
                 {{ messageFont || fontArray[0] }}
                 <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
                </button>
               <ul class="dropdown-menu">
                 <li *ngFor="let font of fontArray" [ngStyle]="{'font-family': font}" (click)="updateMessageFont(font)"><a class="dropdown-item">{{ font }}</a></li>
               </ul>
              </div>
            </div>

            <div class="form-row">
                <label for="bodyBackgroundColor" class="col-form-label">
                    Body Background Color:
                </label>
                <input 
                type="text"
                name="bodyBackgroundColor"
                id="bodyBackgroundColor" 
                formControlName="bodyBackgroundColor" 
                class="form-control">
                <mat-hint>enter a hex code including the #. For gradients, enter full rgba code.</mat-hint>
        </div>

        <div class="form-row">
                <label for="bodyBackgroundColor" class="col-form-label">
                    Text Shadow:
                </label>
                <input 
                type="text"
                name="textShadow"
                id="textShadow" 
                formControlName="textShadow" 
                class="form-control">
                <mat-hint>Make it pop! Format: color | offset-x | offset-y | blur-radius</mat-hint>
        </div>

            <div class="form-row">
                    <label for="recipient" class="col-form-label">
                        To
                    </label>
                    <input 
                    type="text"
                    name="to"
                    inputmode="latin-name"
                    id="to" 
                    formControlName="to" 
                    class="form-control"
                    placeholder="Anne">
            </div>

            <div class="form-row">
                    <label for="recipient" class="col-form-label">
                        Message
                    </label>
                    <textarea name="message" id="message" cols="30" rows="4" class="form-control" formControlName="message" placeholder="Wishing you the best!"></textarea>
            </div>

            <div class="form-row">
                    <label for="recipient" class="col-form-label">
                        From
                    </label>
                    <textarea name="from" id="from" cols="30" rows="4" type="text" formControlName="from" 
                    class="form-control"
                    placeholder="Bill">
                        
                    </textarea>
                    <!-- <input 
                    type="text"
                    name="message"
                    id="from" 
                   > -->
            </div>

            <div class="form-row">
                <label for="releaseDate">
                    Set release date
                </label>
               <input 
               type="date"
               class="form-control"
               formControlName="releaseDate">
               <div class="form-text text-muted">Leave unset to publish immediately.</div>
            </div>

           <div class="form-row">
               <label for="price" class="col-form-label">
                   Regular Price
                </label>
                <input 
                type="text"
                inputmode="decimal"
                appOnlyNumber allowDecimals="true" allowSign="false"
                allowDecimals="true"
                name="price"
                id="price"
                formControlName="price"
                class="form-control col-6">
                <span 
                class="form-text text-muted"
                *ngIf="onSetPrice()">Please ensure you have included 2 decimal places, e.g. "12.00", "1.99" or "2.50".</span>
           </div>

           <div class="form-row">
            <label for="price" class="col-form-label">
                Price EUR
             </label>
             <input 
             type="text"
             inputmode="decimal"
             appOnlyNumber allowDecimals="true" allowSign="false"
             allowDecimals="true"
             name="priceEUR"
             id="priceEUR"
             formControlName="priceEUR"
             class="form-control col-6">
             <span 
             class="form-text text-muted"
             *ngIf="onSetPriceEUR()">Please ensure you have included 2 decimal places, e.g. "12.00", "1.99" or "2.50".</span>
        </div>

        <div class="form-row">
            <label for="price" class="col-form-label">
                Price USD
             </label>
             <input 
             type="text"
             inputmode="decimal"
             appOnlyNumber allowDecimals="true" allowSign="false"
             allowDecimals="true"
             name="priceUSD"
             id="priceUSD"
             formControlName="priceUSD"
             class="form-control col-6">
             <span 
             class="form-text text-muted"
             *ngIf="onSetPriceUSD()">Please ensure you have included 2 decimal places, e.g. "12.00", "1.99" or "2.50".</span>
        </div>

           <div class="form-row">
                <label for="salePrice" class="col-form-label">
                    On Sale Price
                 </label>
                     <input 
                    type="text"
                    inputmode="decimal"
                    appOnlyNumber allowDecimals="true" allowSign="false"
                    name="salePrice"
                    id="salePrice"
                    formControlName="salePrice"
                    class="form-control col-6">
                    <span 
                    class="form-text text-muted"
                    *ngIf="onSetSalePrice()">Please ensure you have included 2 decimal places, e.g. "12.00", "1.99" or "2.50".</span>
            </div>


            <div formGroupName="saleWindow" *ngIf="this.formECard.get('newECard.salePrice').valid">
                    <div class="form-extra form-row">
                        <div class="form-row">
                            <label for="saleStartDate">
                                Sale Start Date
                            </label>
                            <input type="datetime"
                            name="saleStartDate"
                            id="saleStartDate"
                            formControlName="saleStartDate"
                            class="form-control">
                        </div>
                        <div class="form-row">
                            <label for="saleStartDate">
                            Sale End Date
                            </label>
                            <input type="datetime"
                            name="saleEndDate"
                            id="saleEndsDate"
                            formControlName="saleEndDate"
                            class="form-control">
                        </div>
                    </div>
            </div>

            <div class="form-row">
                <label for="sendLimit" class="col-form-label">
                    Send Limit (ecards in pack)
                </label>
                <input 
                type="number"
                name="sendLimit"
                id="sendLimit" 
                formControlName="sendLimit" 
                class="form-control">
        </div>
    
            <div class="form-row">
            <div class="col-9">
                <label class="col-form-label form-inline">Reviews</label> 
            </div>
            <div class="col-2 mr-1">
                <label class="switch">
                <input 
                type="checkbox" 
                name="review" 
                id="review" 
                formControlName="review" 
                class="custom-control switch">
                <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div class="form-row">
            <label for="isChristmas" class="col-form-label">isChristmas</label>
            <input type="checkbox"
            data-toggle="true"
            formControlName="isChristmas">
        </div>

        <div class="form-row">
            <label for="occasions" class="col-form-label">Occasion</label>
            <div *ngIf="occasionsList.length != 0">
            <span *ngFor="let o of occasionsList">

                <button class="btn m-2" [ngClass]="{ 'btn-primary': o.selected == true}" type="button" (click)="onOcc(o)">
                {{ o.name }}
              </button>
      
            </span>
        </div>
        Selected Occasions:
        <span *ngFor="let sel of occasions">
            {{ sel.name }}
        </span>
            <!-- <div *ngIf="occ$">
            <span *ngFor="let occ of (occ$ | async)">
                <button class="btn btn-outline-primary m-2" [ngClass]="{'btn-primary': occ.selected}" click="onOccTrue(occ)">
                {{ occ.occName }}
              </button>
              </span>
            </div> -->
        </div>

        <!-- <div class="form-row">
            <label for="tags" class="col-form-label">Tags</label>
            <input 
            type="text"
            name="tags"
            id="tags"
            formArrayName="tags">
            <button class="btn btn-primary" type="button" (click)="onAddTag()">
                Add
            </button>
        </div> 
        <div class="row" *ngIf="formECard.get('newECard.tags').value">
                {{ formECard.get('newECard.tags').value }}
            </div>

        <div class="form-row">
            <label for="tags" class="col-form-label">Categories</label>
            <input
            name="categories"
            id="categories"
            placeholder="Categories typing box. There should be an array of current categories below."
            formArrayName="categories"
            class="form-control">
            <span *ngFor="let cat of (cat$ | async)" formArrayName="categories">
                    <button type="button" class="btn m-2 cat-selector" (click)="onCatSelected(cat.catId)" [ngClass]="{'cat-selector-selected': onCatSelected()}" >
                    {{ cat.catName }} {{ cat.catId }}
                  </button>
                  </span>
        </div> -->

        <!-- <div class="form-row">
            <label for="tags" formGroupName="selfDestructRules" class="col-form-label">Self Destruct Rules</label>
            Destroy after <input type="number" fromControlName="resendLimit"> resends/forwards, or <br>
            Destroy after <input type="number" formControlName="viewLimit"> views.
        </div> -->

    </div>

    <!-- old button placement -->
    
    </div>

    </div>
    <div class="col-md-6 col-md-push-6 shadow-lg">
        <i>Card Preview</i>
            <div class="card">
                <div *ngIf="thumbnail">
                        <span class="to">
                                <h5 [ngStyle]="{color: getColor(), 'font-family': personageFont, 'text-shadow': formECard.get('newECard.textShadow').value}">
                                  {{ formECard.get('newECard.to').value }}
                                </h5>
                              </span>
                               <span class="overlay">
                              <h4 [ngStyle]="{color: getColor(), 'font-family': messageFont, 'text-shadow': formECard.get('newECard.textShadow').value}">{{ formECard.get('newECard.message').value }}</h4>
                            </span>
                            <span class="from" >
                              <h5 [ngStyle]="{color: getColor(), 'font-family': personageFont, 'text-shadow': formECard.get('newECard.textShadow').value}">{{ formECard.get('newECard.from').value }}</h5>
                            </span>
                        <img 
                        [src]="thumbnail.downloadURL" 
                        alt="thumbnail-preview"
                        class="card-img-top img-fluid">
                </div>
                </div>
            <div class="card-body">
                <div class="card-title" style="text-align:center;">
                    {{ formECard.get('newECard.name').value }}
                </div>
                <div class="card-subtitle" style="text-align:center;">
                        {{ formECard.get('newECard.price').value | currency:currency.toUpperCase():'symbol' }}
                </div>
                <div class="card-text whitespace">{{ formECard.get('newECard.synopsis').value }}</div>
            </div>
            <hr>
            <i>Detail Preview</i>
            <div class="row">
                    <div class="col-xs-12 col-md-4">
                      <video></video>
                      <div *ngIf="thumbnail">
                            <img 
                            [src]="thumbnail.downloadURL" 
                            alt="thumbnail-preview"
                            class="figure-img img-fluid">
                    </div>
                      </div>
                    <div class="col-xs-12 col-md-7 offset-md-1">
                        <h2> {{ formECard.get('newECard.name').value }} </h2>
                        <h5> {{ formECard.get('newECard.price').value | currency:'GBP':'symbol' }} </h5>
                        <h5> Send Limit: {{ formECard.get('newECard.sendLimit').value }} </h5>
                        <p> {{ formECard.get('newECard.synopsis').value }} </p>
                    </div>
                </div>
        </div>
</div>
<div class="form-row">
    <div class="col-sm-6"></div>
    <div class="col-sm-6">
        <button
    class="btn btn-primary float-sm-right"
    [disabled]="!formECard.valid">
        {{ editMode ? 'Update E-Card' : 'Add New E-Card' }}
    </button>
    </div>
</div>
</form>
<hr>
<!-- [disabled]="!formECard.valid" -->
