<div class="container">
  <section class="m-3">
  <div class="col-12 col-md-6 offset-md-3">
  <h2>About LyricalDays.com</h2>
  <p>
    LyricalDays.com is a small independantly run business based in Northern
    Ireland, United Kingdom. It is currently comprised of a team of 1, so do be
    patient with us (me) if we take a while to get around to a request of yours!
  </p>
  <p>
    We appreciate your love and support. Thank you for choosing LyricalDays.com.
  </p>
</div>
</section>
</div>
