<h4 class="text-center">
  Categories
</h4>
<p>
  This is where the categories of "Who is it for?" are placed. Think his or hers, child or adult.
</p>

<form [formGroup]="catForm" (ngSubmit)="onCat()">
  <div class="form-inline">
  <label for="addCat" class="sr-only">{{ editMode ? 'Update Category' : 'Add Category' }}</label>
<input type="text" class="form-control ml-1" formControlName="catControl">
<button class="btn btn-primary ml-1" type="submit" [disabled]="!catForm.valid">
  {{ editMode ? 'Update' : 'Add' }}
</button>
<span *ngIf="editMode">
  <button class="btn btn-warning ml-1" type="button" (click)="onCancelEdit()">
    Cancel
</button>
<button class="btn btn-danger ml-1" type="button" (click)="onDelete()">
  Delete
</button>
</span>
</div>
</form>


<hr>

<span *ngFor="let category of (category$ | async)">
  <button class="btn btn-outline-primary m-2" (click)="onEdit(category.catId, category.catName)">
  {{ category.catName }}
</button>
</span>

<!-- "onAddCat(cat.value)" -->