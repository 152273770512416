<div>
    <div>
      <mat-spinner *ngIf="ecardsLoading"></mat-spinner>
      <div class="mx-auto">
      <div class="card-columns">
        <a [routerLink]="['/ecards', ecard.id, ecard.name | lowercase | removeSpaces]" *ngFor="let ecard of latestEcards">
          <div class="card">
            <div class="d-flex align-items-center img-v-middle">
              <div *ngIf="ecard.sendLimit > 1 && ecard.sendLimit < 10" class="float">
                <p [ngClass]="{'pack-count-portrait': ecard.styleOptions.aspectRatio == 'portrait', 'pack-count-landscape': ecard.styleOptions.aspectRatio == 'landscape'}">{{ ecard.sendLimit }}</p>
              <img src="../../../assets/icons/multi-pack_icon_2.svg" alt="multipack" class="img img-fluid multi" [ngClass]="{'multipack-portrait': ecard.styleOptions.aspectRatio == 'portrait', 'multipack-landscape': ecard.styleOptions.aspectRatio == 'landscape'}">
              </div>
              <!-- for card packs greater than 10 like the Christmas ones -->
              <div *ngIf="ecard.sendLimit >= 10" class="float">
                <p [ngClass]="{'pack-count-portrait-lg': ecard.styleOptions.aspectRatio == 'portrait', 'pack-count-landscape-lg': ecard.styleOptions.aspectRatio == 'landscape'}">{{ ecard.sendLimit }}</p>
              <img src="../../../assets/icons/multi-pack_icon_2.svg" alt="multipack" class="img img-fluid multi" [ngClass]="{'multipack-portrait': ecard.styleOptions.aspectRatio == 'portrait', 'multipack-landscape': ecard.styleOptions.aspectRatio == 'landscape'}">
              </div>
             
                <img 
                [src]="ecard.thumbnail.downloadURL" 
                alt="{{ ecard.mediaAltText }}" 
                class="card-img-top">
              </div>
            <div class="card-body my-auto">
              <div class="card-title ld-dark-blue mt-auto" style="text-align:center;">
                 <h4 class="avenir-next">{{ ecard.name }}</h4>
              </div>
              <div class="card-subtitle" style="text-align:center;">
                <div class="mx-auto">
                  <div class="row">
                    <div class="col-12 text-center ld-dark-blue">
                      <h5 *ngIf="currency == 'gbp'"> {{ ecard.price | currency:currency.toUpperCase():'symbol' }} </h5>
                      <h5 *ngIf="currency =='usd' && ecard.priceUSD"> {{ ecard.priceUSD | currency:currency.toUpperCase():'symbol' }} </h5>
                      <h5 *ngIf="currency =='eur' && ecard.priceEUR"> {{ ecard.priceEUR | currency:currency.toUpperCase():'symbol' }} </h5>
                  <!-- <p *ngFor="let occasionName of ecard.occasions">{{ occasionName.name }}</p> -->
                  <!-- <mat-hint *ngIf="ecard.releaseDate"> {{ ecard.releaseDate }} </mat-hint> -->
                </div>
                <!-- <div class="col-6">
                  <h5>Cards in pack: {{ ecard.sendLimit }}</h5>
                </div> -->
                  </div>
                            
                </div>
              </div>
              <!-- <div class="card-text">{{ ecard.synopsis }}</div> -->
            </div>
          </div>
        </a>
      
      </div>
    </div>
  </div>
