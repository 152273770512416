<h2 mat-dialog-title align="center">Select or Add New Media</h2>
<mat-dialog-content>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Upload New Media
      </mat-panel-title>
      <mat-panel-description>
        Media Drop Zone
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-media-uploader></app-media-uploader>
  </mat-expansion-panel>
  <div>
    <button type="button" class="btn btn-primary" (click)="onUpdateAllMetadata()">Update all metadata of media</button>
  </div>

  <mat-grid-list cols="4" rowHeight="" class="mt-3">
    <mat-grid-tile *ngFor="let mediaItem of mediaManagerItem">
      <span
        (click)="onSelected(mediaItem)"
        [ngClass]="{ selected: mediaItem.selected == true }"
      >
        <img *ngIf="mediaItem.mediaType == 'image'"
          src="{{ mediaItem.downloadURL }}"
          alt="{{ mediaItem.storageRef }}"
          class="img-fluid"
          [ngClass]="{ selected: mediaItem.selected == true }"
        />

        <video 
        *ngIf="mediaItem.mediaType != 'image'"
        #videoPlayer 
        width="auto" height="auto" 
        class="img-fluid" 
        loop
        autoplay
        preload="auto" 
        playsinline
        oncontextmenu="return false;">
            <source [src]="mediaItem.downloadURL" type="video/mp4">
            <!-- <source src="movie.ogg" type="video/ogg"> -->
            Your browser does not support the video tag.
          </video>
      </span>
    </mat-grid-tile>
  </mat-grid-list>

  <p>Passed data: {{ passedData.mediaCount }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    [disabled]="onMedia()"
    (click)="onSubmitMediaItem()"
  >
    Yes
  </button>
  <button mat-button [mat-dialog-close]="false">No</button>
</mat-dialog-actions>
