<h4 class="text-center">
  Tags
</h4>

<p>
  This is for specific themes of the card or item, such as humour, seaside, cute, adorable, floral etc. are described.
</p>
<form [formGroup]="tagForm" (ngSubmit)="onTag()">
  <div class="form-inline">
  <label for="addTag" class="sr-only">{{ editMode ? 'Update Tag' : 'Add Tag' }}</label>
<input type="text" class="form-control ml-1" formControlName="tagControl">
<button class="btn btn-primary ml-1" type="submit" [disabled]="!tagForm.valid">
  {{ editMode ? 'Update' : 'Add' }}
</button>
<span *ngIf="editMode">
  <button class="btn btn-warning ml-1" type="button" (click)="onCancelEdit()">
    Cancel
</button>
<button class="btn btn-danger ml-1" type="button" (click)="onDelete()">
  Delete
</button>
</span>
</div>
</form>

<hr>
<div class="row">
  <div class="col-12">
    <span *ngFor="let tg of (tag$ | async)">
      <button class="btn btn-outline-primary m-2" (click)="onEdit(tg.tagId, tg.tagName)">
      {{  tg.tagName }}
      </button>
  </span>
  </div>
</div>
