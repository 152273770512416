<div class="container">
  <h1>TERMS AND CONDITIONS</h1>

  <p>Last Updated 03 December 2019</p>

  <ol>
    <li>
      <h6>Agreement to Terms</h6>
      <ol>
        <li>
          <p>
            These Terms and Conditions constitute a legally binding agreement
            made between you, whether personally or on behalf of an entity
            ("you"), and Lyrical Days, located in the United Kingdom ("we",
            "us", "our"), concerning your access to and use of the Lyrical Days
            (lyricaldays.com) website as well as any related applications
            ("Site").
          </p>
          <p>
            The Site provides the following products and services: a website
            and/or webapp for you to browse, purchase, customise and send
            electronic greetings cards ("ecards", "products") to a person(s)
            whom is known to them ("contact"). ("Products and Services") You
            agree that by accessing the Site and/or Products and Services, you
            have read, understood, and agree to be bound by all of these Terms
            and Conditions.
          </p>
          <p>
            If you do not agree with all of these Terms and Conditions, then you
            are prohibited from using the Site, Products and Services and you
            must discontinue use immediately. We recommend that you print a copy
            of these Terms and Conditions for future reference.
          </p>
        </li>
        <li>
          <p>
            The supplemental policies set out in Section 1.7 below, as well as
            any supplemental terms and condition or documents that may be posted
            on the Site from time to time, are expressly incorporated by
            reference.
          </p>
        </li>
        <li>
          <p>
            We may make changes to these Terms and Conditions at any time. The
            updated version of these Terms and Conditions will be indicated by
            an updated “Revised” date and the updated version will be effective
            as soon as it is accessible. You are responsible for reviewing these
            Terms and Conditions to stay informed of updates. Your continued use
            of the Site represents that you have accepted such changes.
          </p>
        </li>
        <li>
          <p>
            We may update or change the Site from time to time to reflect
            changes to our products, our users' needs and/or our business
            priorities.
          </p>
        </li>
        <li>
          <p>
            Our site is directed to people residing in the United Kingdom. The
            Products and Services provided by the Site are not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country.
          </p>
        </li>
        <li>
          <p>
            The Site is intended for users who are at least 18 years old. If you
            are under the age of 18, you are not permitted to register for the
            Site or use the Products and Services without parental permission.
          </p>
        </li>
        <li>
          <p>
            Additional policies which also apply to your use of the Site
            include:
          </p>
          <ul>
            <li>
              <p>
                ● Our Privacy Notice
                <a [routerLink]="['/', 'legal', 'privacy-policy']"
                  >Privacy Policy</a
                >, which sets out the terms on which we process any personal
                data we collect from you, or that you provide to us. By using
                the Site, you consent to such processing and you warrant that
                all data provided by you is accurate.
              </p>
            </li>
            <li>
              <p>
                ● Our Acceptable Use Policy
                <a [routerLink]="['/', 'legal', 'acceptable-use-policy']"
                  >Acceptable Use Policy</a
                >, which sets out the permitted uses and prohibited uses of the
                Site. When using the Site, you must comply with this Acceptable
                Use Policy.
              </p>
            </li>
            <li>
              <p>
                ● Our Cookie Policy
                <a [routerLink]="['/', 'legal', 'cookie-policy']"
                  >Cookie Policy</a
                >, which sets out information about the cookies on the Site.
              </p>
            </li>
            <li>
              <p>
                ● If you purchase digital goods from the Site, our terms and
                conditions of supply
                <a [routerLink]="['/', 'legal', 'supply-conditions']"
                  >Conditions of Supply Policy</a
                >
                will apply to the sales.
              </p>
            </li>
          </ul>
        </li>
      </ol>
    </li>

    <br />

    <li>
      <h6>Acceptable Use</h6>
      <ol>
        <li>
          <p>
            You may not access or use the Site for any purpose other than that
            for which we make the site and our Products and services available.
            The Site may not be used in connection with any commercial endeavors
            except those that are specifically endorsed or approved by us.
          </p>
        </li>
        <li>
          <p>
            You may not access or use the Site for any purpose other than that
            for which we make the site and our Products and services available.
            The Site may not be used in connection with any commercial endeavors
            except those that are specifically endorsed or approved by us.
          </p>
        </li>
        <li>
          <p>
            As a user of this Site, you agree not to:
          </p>
          <ul>
            <li>
              <p>
                ● Systematically retrieve data or other content from the Site to
                a compile database or directory without written permission from
                us
              </p>
            </li>
            <li>
              <p>
                ● Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users to send unsolicited
                email or creating user accounts under false pretenses
              </p>
            </li>
            <li>
              <p>
                ● Use a buying agent or purchasing agent to make purchases on
                the Site
              </p>
            </li>
            <li>
              <p>
                ● Use the Site to advertise or sell goods and Products and
                services
              </p>
            </li>
            <li>
              <p>
                ● Circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any content or enforce
                limitations on the use
              </p>
            </li>
            <li>
              <p>
                ● Engage in unauthorized framing of or linking to the Site
              </p>
            </li>
            <li>
              <p>
                ● Trick, defraud, or mislead us and other users, especially in
                any attempt to learn sensitive account information such as user
                passwords
              </p>
            </li>
            <li>
              <p>
                ● Make improper use of our support services, or submit false
                reports of abuse or misconduct
              </p>
            </li>
            <li>
              <p>
                ● Engage in any automated use of the system, such as using
                scripts to send comments or messages, or using any data mining,
                robots, or similar data gathering and extraction tools
              </p>
            </li>
            <li>
              <p>
                ● Interfere with, disrupt, or create an undue burden on the Site
                or the networks and Products and services connected to the Site
              </p>
            </li>
            <li>
              <p>
                ● Attempt to impersonate another user or person, or use the
                username of another user
              </p>
            </li>
            <li>
              <p>
                ● Sell or otherwise transfer your profile
              </p>
            </li>
            <li>
              <p>
                ● Use any information obtained from the Site in order to harass,
                abuse, or harm another person
              </p>
            </li>
            <li>
              <p>
                ● Use the Site or our content as part of any effort to compete
                with us or to create a revenue-generating endeavor or commercial
                enterprise
              </p>
            </li>
            <li>
              <p>
                ● Advertise products or Products and services not intended by us
              </p>
            </li>
            <li>
              <p>
                ● Misrepresent experience, skills, or information about a User
              </p>
            </li>
            <li>
              <p>
                ● Use the Site in a manner inconsistent with any applicable laws
                or regulations
              </p>
            </li>
            <li>
              <p>
                ● Disparage, tarnish, or otherwise harm, in our opinion, us
                and/or the Site
              </p>
            </li>
            <li>
              <p>
                ● Use, launch, or engage in any automated use of the system,
                such as using scripts to bulk request data from our servers,
                generate new accounts, robots, scrapers, offline readers, or
                similar data gathering and extraction tools
              </p>
            </li>
            <li>
              <p>
                ● Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material that interferes with
                any party’s uninterrupted use and enjoyment of the Site, or any
                material that acts as a passive or active information collection
                or transmission mechanism
              </p>
            </li>
            <li>
              <p>
                ● Copy or adapt the Site’s software, including but not limited
                to Flash, PHP, HTML, JavaScript, or other code
              </p>
            </li>
            <li>
              <p>
                ● Delete the copyright or other proprietary rights notice from
                any of the content
              </p>
            </li>
            <li>
              <p>
                ● Harass, annoy, intimidate, or threaten any of our employees,
                agents, or other users
              </p>
            </li>
            <li>
              <p>
                ● Attempt to access any portions of the Site that you are
                restricted from accessing
              </p>
            </li>
            <li>
              <p>
                ● Decipher, decompile, disassemble, or reverse engineer any of
                the software comprising or in any way making up a part of the
                Site
              </p>
            </li>
            <li>
              <p>
                ● Download, record, screen capture, copy or otherwise obtain
                animations, images or other media of the Site
              </p>
            </li>
            <li>
              <p>
                ● Falsely imply a relationship with us or another company with
                whom you do not have a relationship
              </p>
            </li>
          </ul>
        </li>
      </ol>
    </li>

    <br />

    <li>
      <h6>
        Information you provide to us
      </h6>
      <ol>
        <li>
          <p>
            You represent and warrant that: (a) all registration information you
            submit will be true, accurate, current, and complete and relate to
            you and not a third party; (b) you will maintain the accuracy of
            such information and promptly update such information as necessary;
            (c) you will keep your password confidential and will be responsible
            for all use of your password and account; (d) you have the legal
            capacity and you agree to comply with these Terms and Conditions;
            and (e) you are not a minor in the jurisdiction in which you reside,
            or if a minor, you have received parental permission to use the
            Site.
          </p>
          <p>
            If you know or suspect that anyone other than you knows your user
            information (such as an identification code or user name) and/or
            password you must promptly notify us at admin@lyricaldays.com.
          </p>
        </li>

        <li>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current or incomplete, we may suspend or terminate your account. We
            may remove or change a user name you select if we determine that
            such user name is inappropriate.
          </p>
        </li>
      </ol>
    </li>

    <br />
    <li>
      <h6>
        Our Content
      </h6>
      <ol>
        <li>
          <p>
            Unless otherwise indicated, the Site and its Products and Services
            including source code, databases, functionality, software, website
            designs, audio, video, text, photographs, animations, paintings,
            illustrations, and graphics on the Site ("Our Content") are owned or
            licensed to us, and are protected by copyright and trade mark laws.
          </p>
        </li>
        <li>
          <p>
            Except as expressly provided in these Terms and Conditions, no part
            of the Site, Products and Services or Our Content may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.
          </p>
        </li>
        <li>
          <p>
            Provided that you are eligible to use the Site, you are granted a
            limited licence to access and use the Site and Our Content, Products
            and Services to which you have properly gained access solely for
            your personal, non-commercial use.
          </p>
        </li>
        <li>
          <p>
            You shall not (a) try to gain unauthorised access to the Site or any
            networks, servers or computer systems connected to the Site; and/or
            (b) make for any purpose including error correction, any
            modifications, adaptions, additions or enhancements to the Site or
            Our Content, including the modification of the paper or digital
            copies you may have downloaded.
          </p>
        </li>
        <li>
          <p>
            We shall (a) prepare the Site and Our Content with reasonable skill
            and care; and (b) use industry standard virus detection software to
            try to block the uploading of content to the Site that contains
            viruses.
          </p>
        </li>
        <li>
          <p>
            The content, Poducts and Services on the Site are provided for
            personal and private only.
          </p>
        </li>
        <li>
          <p>
            Although we make reasonable efforts to update the information on our
            site, we make no representations, warranties or guarantees, whether
            express or implied, that Our Content on the Site is accurate,
            complete or up to date.
          </p>
        </li>
      </ol>
    </li>
    <br />
    <li>
      <h6>
        Site Management
      </h6>
      <ol>
        <li>
          <p>
            We reserve the right at our sole discretion, to (1) monitor the Site
            for breaches of these Terms and Conditions; (2) take appropriate
            legal action against anyone in breach of applicable laws or these
            Terms and Conditions; (3) remove from the Site or otherwise disable
            all files and content that are excessive in size or are in any way a
            burden to our systems; and (4) otherwise manage the Site in a manner
            designed to protect our rights and property and to facilitate the
            proper functioning of the Site and Products and Services.
          </p>
        </li>
        <li>
          <p>
            We do not guarantee that the Site will be secure or free from bugs
            or viruses.
          </p>
        </li>
        <li>
          <p>
            You are responsible for configuring your information technology,
            computer programs and platform to access the Site and you should use
            your own virus protection software.
          </p>
        </li>
      </ol>
    </li>

    <br />

    <li>
      <h6>
        Modifications to and availability of the Site
      </h6>
      <ol>
        <li>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. We also reserve the right to modify or discontinue
            all or part of the Products and Services without notice at any time.
          </p>
        </li>
        <li>
          <p>
            We cannot guarantee the Site and its Products and Services will be
            available at all times. We may experience hardware, software, or
            other problems or need to perform maintenance related to the Site,
            resulting in interruptions, delays, or errors. You agree that we
            have no liability whatsoever for any loss, damage, or inconvenience
            caused by your inability to access or use the Site or Products and
            Services during any downtime or discontinuance of the Site or
            Products and Services.We are not obliged to maintain and support the
            Site or Products and Services or to supply any corrections, updates,
            or releases.
          </p>
        </li>
        <li>
          <p>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions that may relate to the Products
            and Services, including descriptions, pricing, availability, and
            various other information. We reserve the right to correct any
            errors, inaccuracies, or omissions and to change or update the
            information at any time, without prior notice.
          </p>
        </li>
      </ol>
    </li>

    <br />

    <li>
      <h6>
        Disclaimer/Limitation of Liability
      </h6>
      <ol>
        <li>
          <p>
            The Site and its Products and Services are provided on an as-is and
            as-available basis. You agree that your use of the Site and/or
            Products and Services will be at your sole risk except as expressly
            set out in these Terms and Conditions. All warranties, terms,
            conditions and undertakings, express or implied (including by
            statute, custom or usage, a course of dealing, or common law) in
            connection with the Site and its Products and Services and your use
            thereof including, without limitation, the implied warranties of
            satisfactory quality, fitness for a particular purpose and
            non-infringement are excluded to the fullest extent permitted by
            applicable law.
          </p>

          <p>
            We make no warranties or representations about the accuracy or
            completeness of the Site’s content and are not liable for any (1)
            errors or omissions in content: (2) any unauthorized access to or
            use of our servers and/or any and all personal information and/or
            financial information stored on our server; (3) any interruption or
            cessation of transmission to or from the Site or Products and
            Services; and/or (4) any bugs, viruses, trojan horses, or the like
            which may be transmitted to or through the Site by any third party.
            We will not be responsible for any delay or failure to comply with
            our obligations under these Terms and Conditions if such delay or
            failure is caused by an event beyond our reasonable control.
          </p>
          <p>
            The Site will not be liable for any loss or damage caused by a
            virus, distributed denial-of-service attack, or other
            technologically harmful material that may infect Your computer
            equipment, computer programs, data or other proprietary material due
            to Your use of this Site or to your downloading of any content on
            it, or on any website linked to it.
          </p>
        </li>
        <li>
          <p>
            Our responsibility for loss or damage suffered by you:
          </p>
          <ul>
            <li>
              <p>
                ● We do not exclude or limit in any way our liability to you
                where it would be unlawful to do so. This includes liability for
                death or personal injury caused by our negligence or the
                negligence of our employees, agents or subcontractors and for
                fraud or fraudulent misrepresentation.
              </p>
            </li>
            <li>
              <p>
                ● If we fail to comply with these Terms and Conditions, we will
                be responsible for loss or damage you suffer that is a
                foreseeable result of our breach of these Terms and Conditions,
                but we would not be responsible for any loss or damage that were
                not foreseeable at the time you started using the Site/Products
                and Services.
              </p> 
              <p>
            Notwithstanding anything to the contrary contained in the
            Disclaimer/Limitation of Liability section, our liability to you for
            any cause whatsoever and regardless of the form of the action, will
            at all times be limited to a total aggregate amount equal to the
            greater of (a) the sum of £1.00 (GBP) or (b) the amount paid, if
            any, by you to us for the Products and Services/Site during the six
            (6) month period prior to any cause of action arising. Different
            limitations and exclusions of liability will apply to liability
            arising as a result of the supply of any products to you, which will
            be set out in our Terms and conditions of supply
            <a [routerLink]="['/', 'legal', 'supply-conditions']"
              >Conditions of Supply</a
            >.
          </p>    
        </li>
          <li>
            <p>
              ● Please note that we only provide our Site for domestic and
              private use. You agree not to use our Site for any commercial or
              business purposes, and we have no liability to you for any loss of
              profit, loss of business, business interruption, or loss of
              business opportunity.
            </p>
          </li>
          <li>
            <p>
              ● If defective digital content that we have supplied, damages a
              device or digital content belonging to you and this is caused by
              our failure to use reasonable care and skill, we will either
              repair the damage or pay you compensation. However, we will not be
              liable for damage that you could have avoided by following our
              advice to apply an update offered to you free of charge or for
              damage that was caused by you failing to correctly follow
              installation instructions or to have in place the minimum system
              requirements advised by us.
            </p>
          </li>
          <li>
            <p>
              ● You have legal rights in relation to goods that are faulty or
              not as described. Advice about your legal rights is available from
              your local Citizens' Advice Bureau or Trading Standards office.
              Nothing in these Terms and Conditions will affect these legal
              rights.
            </p>
          </li>
        </ul>
      </ol>
    </li>

    <br />

    <li>
      <h6>
        Term and Termination
      </h6>
      <ol>
        <li>
          <p>
            These Terms and Conditions shall remain in full force and effect
            while you use the Site or Products and Services or are otherwise a
            user of the Site, as applicable. You may terminate your use or
            participation at any time, for any reason, by following the
            instructions for terminating user accounts in your account settings,
            if available, or by contacting us at admin@lyricaldays.com.
          </p>
        </li>
        <li>
          <p>
            Without limiting any other provision of these Terms and Conditions,
            we reserve the right to, in our sole discretion and without notice
            or liability, deny access to and use of the Site and the Products
            and Services (including blocking certain IP addresses), to any
            person for any reason including without limitation for breach of any
            representation, warranty or covenant contained in these Terms and
            Conditions or of any applicable law or regulation.
          </p>
          <p>
            If we determine, in our sole discretion, that your use of the
            Site/Products and Services is in breach of these Terms and
            Conditions or of any applicable law or regulation, we may terminate
            your use or participation in the Site and the Products and Services
            or delete your profile and any content or information that you
            posted at any time, without warning, in our sole discretion.
          </p>
        </li>
        <li>
          <p>
            If we terminate or suspend your account for any reason set out in
            this Section 9, you are prohibited from registering and creating a
            new account under your name, a fake or borrowed name, or the name of
            any third party, even if you may be acting on behalf of the third
            party. In addition to terminating or suspending your account, we
            reserve the right to take appropriate legal action, including
            without limitation pursuing civil, criminal, and injunctive redress.
          </p>
        </li>
      </ol>
    </li>

    <li>
      <h6>
        General
      </h6>
      <ol>
        <li>
          <p>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing.
          </p>
          <p>
            You hereby agree to the use of electronic signatures, contracts,
            orders and other records and to electronic delivery of notices,
            policies and records of transactions initiated or completed by us or
            via the Site. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by other than electronic means.
          </p>
        </li>
        <li>
          <p>
            These Terms and Conditions and any policies or operating rules
            posted by us on the Site or in respect to the Products and Services
            constitute the entire agreement and understanding between you and
            us.
          </p>
        </li>
        <li>
          <p>
            Our failure to exercise or enforce any right or provision of these
            Terms and Conditions shall not operate as a waiver of such right or
            provision.
          </p>
        </li>
        <li>
          <p>
            We may assign any or all of our rights and obligations to others at
            any time.
          </p>
        </li>
        <li>
          <p>
            We shall not be responsible or liable for any loss, damage, delay or
            failure to act caused by any cause beyond our reasonable control.
          </p>
        </li>
        <li>
          <p>
            If any provision or part of a provision of these Terms and
            Conditions is unlawful, void or unenforceable, that provision or
            part of the provision is deemed severable from these Terms and
            Conditions and does not affect the validity and enforceability of
            any remaining provisions.
          </p>
        </li>
        <li>
          <p>
            There is no joint venture, partnership, employment or agency
            relationship created between you and us as a result of these Terms
            and Conditions or use of the Site or Products and Services.
          </p>
        </li>
        <li>
          <p>
            For consumers only - Please note that these Terms and Conditions,
            their subject matter and their formation, are governed by English
            law. You and we both agree that the courts of England and Wales will
            have exclusive jurisdiction expect that if you are a resident of
            Northern Ireland you may also bring proceedings in Northern Ireland,
            and if you are resident of Scotland, you may also bring proceedings
            in Scotland. If you have any complaint or wish to raise a dispute
            under these Terms and Conditions or otherwise in relation to the
            Site please follow this link
            <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>
          </p>
        </li>
        <li>
          <p>
            A person who is not a party to these Terms and Conditions shall have
            no right under the Contracts (Rights of Third Parties) Act 1999 to
            enforce any term of these Terms and Conditions.
          </p>
        </li>
        <li>
          <p>
            In order to resolve a complaint regarding the Products and Services
            or to receive further information regarding use of the Products and
            Services, please contact us by email at admin@lyricaldays.com
          </p>
        </li>
      </ol>
    </li>
  </ol>
</div>
