<div id="container">
  <canvas #canvas id='canvas'id="overlay" class="d-none d-lg-block"></canvas>
</div>

<section class="hero-banner">
  <div class="background-img-wrapper">
    <img  class="img img-fluid d-none d-sm-none d-md-block d-lg-block background-img background-img--cover background-img-center-center" src="./../../../assets/media/send yur thoughts & best wishes.png" alt="LyricalDays.com animated ecards">
    <img  class="img img-fluid img-mobile d-block d-sm-block d-md-none d-lg-none background-img background-img--cover background-img-center-bottom" src="./../../../assets/media/send yur thoughts & best wishes mobile.png" alt="LyricalDays.com animated ecards">
  </div>
  <div class="container pb-5">
    <div class="hero-banner_inner">
      <div class="row justify-content-md-start align-content-center align-items-md-center">
        <div class="col-12 col-md-8 col-lg-9 col-xl-7">
          <div class="glass p-5">
            <h1 class="display-4 bold olive-beret text-center"  style="font-weight: 600;"> with all my love</h1>
            <p class="lead col-sm-12 text-center" style="font-weight: 400;">Make any day special 
              <!-- <span class="olive-beret align-top" style="font-size: 1.15em;">wishing you the best</span> -->
               with a cheerful animated ecard.
                </p>
                <div class="d-flex flex-row justify-content-center">
                  <button class="btn btn-primary  btn-sm p-3 pl-5 pr-5 pb-3 rounded-pill btn-grad d-none d-sm-none d-md-block d-lg-block" routerLink="/ecards/Birthday">shop now</button>
                  <button class="btn btn-secondary btn-sm p-3 pl-5 pr-5 rounded-pill mr-2 ml-4 d-none d-sm-none d-md-block d-lg-block" routerLink="/signup"> create account</button>
              </div>
                  <button class="btn btn-primary p-3 pl-5 pr-5 pb-3 rounded-pill btn-grad  mt-1  col-12 d-sm-block d-md-none d-lg-none" routerLink="/ecards/Birthday">shop now</button>
                  <button class="btn btn-secondary p-3 pl-5 pr-5 pb-3 rounded-pill mt-1 mr-1 col-12 d-sm-block d-md-none d-lg-none" routerLink="/signup"> create account</button>
                
          </div>
        </div>
        <div class="col-lg-5 col-md-3"></div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
  <div class="col-12 col-md-6">
     <h1 class="display-3">
    send your thoughts and best wishes with a Lyrical Days animated ecard.
  </h1>
  <div class="d-none d-sm-none d-md-none d-lg-block">
    <p class="lead col-sm-12" >Pick an ecard and write it with love<br> 
     Send it to their inbox. Instantly, done.
       </p>
     <hr class="my-4">
</div>
  </div>
 
  <div class="col-12 col-md-6">
    <div *ngIf="platformBrowser" class="d-none d-sm-block col-xl-4" [ngClass]="{'col-lg-6': 'platformBrowser'}">
      <div class="align-middle vid">
        <video
          #videoPlayer
          width="auto"
          height="auto"
          preload="auto"
          loop
          autoplay="true"
          muted="muted"
          class="img img-fluid drop-shadow"
        >
          <source [src]="mediaSource" />
        </video>
      </div>
      </div>
    </div>
  </div> -->
 
  
</section>
<br>
<!-- <div class="">
  <div id="container" class="hero-container jumbotron jumbotron-fluid" [ngClass]="{'jumbotron-winter': isWinter, 'jumbotron-spring': isSpring, 'jumbotron-autumn': isAutumn}">
    <img [src]="coverArt"
     alt="Spring's Summer - ecards at LyricalDays.com" 
     class="background-img d-none d-sm-block img img-fluid">
    
  <div class="float-info" [ngClass]="{'float-info-winter': isWinter, 'float-info-spring': isSpring, 'float-info-autumn': isAutumn}">
    <h1 class="display-5 d-none d-sm-none d-md-none d-lg-block">Lyrical Days</h1> -->
    <!-- <h1 class="display-5 d-sm-block d-md-block d-lg-none">Valentine <br> E-Cards</h1> -->
    <!-- <br>   
    <div class="d-none d-sm-none d-md-none d-lg-block">
               <p class="lead col-sm-12" >Pick an ecard and write it with love<br> 
                Send it to their inbox. Instantly, done.
                  </p>
                <hr class="my-4">
    </div> -->
    <!-- <h1 class="display-4">Welcome to the Song of Life.</h1> -->
     
        <!-- <p>Send your thoughts and best wishes to those you love instantly with an animated ecard.</p> -->
        <!-- <p class="lead">
          <a class="btn btn-primary btn-lg row" role="button" routerLink="/ecards">Shop Now <mat-icon>chevron_right</mat-icon></a>
        </p> -->
  <!-- </div>
  
  </div>
  </div> -->
<!-- <div class="jumbotron jumbotron-fluid">
  <h1 class="display-4">Hello, world!</h1>
  <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
  <hr class="my-4">
  <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  <p class="lead">
    <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  </p>
</div> -->
<section class="mt-3 mb-3" style="background-color: #fcfcfc;">
  <div>

  <div class="col-12">
    <div class="row row justify-content-center">
       <h2 class="olive-beret text-center ld-dark-blue" style="font-size: 3.5em;">latest ecards</h2>
    </div>
  </div>

  <div class="col-12">
    <app-latest-ecards></app-latest-ecards>
  </div>

</div>
</section>
<!-- <div class="col-12">
  <h1 class="display-3 text-muted text-center align-middle align-self-center m-5">
    <i>" ...best of wishes, my love... "
      </i>
    </h1>
</div> -->

<!-- <div class="col-12 pitch">
<p class="display-4 text-center">Buy 1 ecard and send it to 99 people</p>
</div> -->


<!-- Explain how this all works -->
<section class="text-white height-400" [ngClass]="{'feature-gradient-ice-blue': isWinter, 'feature-gradient-autumn-wind': isAutumn, 'feature-gradient-candy-cloud': isSpring }">
<h3 class="display-3 text-center m-3 mb-3 janesville janesville-bigger janesville-sm p-0">
How does it work?
</h3>
<div class="container">
<div class="row">
  <div class="col-sm-4">
    <h5 class="text-center alice font-larger">Step 1</h5>
    <div class="text-center">
      <mat-icon class="material-icons md-48 m-3">account_circle</mat-icon>
      <p class="text-center stronger">Create a free account</p>
    </div>
  </div>

  <div class="col-sm-4">
    <h5 class="text-center alice font-larger">Step 2</h5>
    <div class="text-center">
      <mat-icon class="material-icons md-48 m-3"
        >add_shopping_cart</mat-icon
      >
      <p class="text-center stronger">Pick a card</p>
    </div>
  </div>

  <div class="col-sm-4">
    <h5 class="text-center alice font-larger">Step 3</h5>
    <div class="text-center">
      <mat-icon class="material-icons md-48 m-3">create</mat-icon>
      <p class="text-center stronger">Customise and send</p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="m-3 mt-5 mb-5">
      <!-- <h4 class="text-center">Start now!</h4>  -->
      <div class="text-center">
        <button
        [routerLink]="['/signup']" routerLinkActive="router-link-active" 
          class="btn btn-secondary btn-lg rounded-pill text-center z-up"
        >
          Create account <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
</div>
</section>

<!-- Shopper suggestions -->
<section class="height-400">
<div class="row m-0">
<div class="col-sm-12 col-xl-8 height-400 align-items-center" [ngClass]="{'col-lg-6': 'platformBrowser', 'indigo': isWinter || isAutumn, 'blue': isSpring}">
  <h3 class="display-4 text-center janesville janesville-biggest font-larger p-4">
    our ecards are completely customisable
  </h3>
  <p class="p-5 text-justify col-md-6 offset-md-3 col-lg-6 offset-lg-3 font-larger">
    See a card you like? You can change the caption on it to suit your
    occasions, personalities and humour.
  </p>
</div>

<div *ngIf="platformBrowser" class="d-none d-sm-block col-xl-4" [ngClass]="{'col-lg-6': 'platformBrowser'}">
<div class="align-middle vid">
  <video
    #videoPlayer
    width="auto"
    height="auto"
    preload="auto"
    loop
    autoplay="true"
    muted="muted"
    class="img img-fluid drop-shadow"
  >
    <source [src]="mediaSource" />
  </video>
</div>
</div>
</div>
</section>

<div class="d-flex justify-content-center flex-wrap">


 <div class="sticker pink">
      <p class="sweety-donut text-white text-center align-middle align-self-center my-auto">
        Sugar is sweet and so are you
      </p>
    </div>


    <div class="sticker blue">
      <p class="sweety-donut text-white text-center align-middle align-self-center my-auto">
        Recently, I think of You...
      </p>
    </div>

    <div class="sticker orange">
        <p class="sweety-donut text-white text-center align-middle align-self-center my-auto">
          You are The Jam to My Peanut Butter
        </p> 
      </div>

    <div class="sticker lilac">
        <p class="sweety-donut text-white text-center align-middle align-self-center my-auto">
            Making me Happy When Skies are Grey
        </p>
      </div>
  
    <div class="sticker yellow">
        <p class="sweety-donut text-white text-center align-middle align-self-center my-auto">
          Cheery Bye & Thank You
        </p>
    </div>



</div>
