
<ul class="nav nav-tabs">
    <!-- <a routerLink="./"
    (click)="all()"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
    class="nav-link nav-fill" 
    style="width: 33%;">
        <li class="nav-item">All</li>
    </a> -->
    <a [routerLink]="['ecards']"
    routerLinkActive="active"
    (click)="notAll()"
    class="nav-link nav-fill" 
    style="width: 50%;">
        <li class="nav-item">E-Cards</li></a>
    <a [routerLink]="['gifts']"
    routerLinkActive="active"
    (click)="notAll()"
    class="nav-link nav-fill" 
    style="width: 50%;">
     <li class="nav-item">Gifts</li></a>
</ul>
<div *ngIf="displayAll">
<app-all-products></app-all-products>
</div>

<router-outlet></router-outlet>
