
<p>
  Keep the tax man happy!
</p>
<p>
  All current prices are NOT inclusive of any TAX.  <br> // OR, there is the option to calculate tax by tax fraction. 
  So profits will vary depending on where the sales are, 
  rather than putting prices up to keep profits inline univsersally...sacrifice profits for a univsersally flat rate?
</p>
<p>
  Please update settings below to...keep that tax man happy!
</p>
<p>
  KUDOS (>.<)
</p>
<p>
    <b> Zero rate</b>
  Zero-rated means that the goods are still VAT-taxable but the rate of VAT you must charge your customers is 0%. 
You still have to record them in your VAT accounts and report them on your VAT Return.
</p>
<p>
    <b>Reduced rate</b> 
    When you charge this rate can depend on what the item is as well as the circumstances of the sale
</p>
<p>
    <b>Standard rate</b> 
    Most goods and services are standard rate. You should charge this rate unless the goods or services are classed as reduced or zero-rated.
</p>

<ul class="nav nav-tabs">
    <a routerLink="./"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
    class="nav-link nav-fill" 
    style="width: 33%;">
        <li class="nav-item">TAX Settings</li>
    </a>
    <a [routerLink]="['/ecards']"
    routerLinkActive="active"
    class="nav-link nav-fill" 
    style="width: 33%;">
        <li class="nav-item">Manage & Set Standard Rates</li></a>
    <a [routerLink]="['gifts']"
    routerLinkActive="active"
    class="nav-link nav-fill" 
    style="width: 33%;">
     <li class="nav-item">Manage & Set Reduced Rates</li></a>
     <a routerLink="./"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
    class="nav-link nav-fill" 
    style="width: 33%;">
        <li class="nav-item">Manage & Set Zero Rates</li>
    </a>
</ul>

<label for="tax-by">Calculate TAX By:</label>
<div
  class="btn-group dropdown" 
  appDropdown >
  <button
  type="button"
  class="btn dropdown-toggle">
    Calculate tax by...
    <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
  </button>
<ul class="dropdown-menu">
  <li class="dropdown-item">Shop Base (where I am)</li>
  <li class="dropdown-item">Customer Shipping Address</li>
  <li class="dropdown-item"> Customer Billing Address</li>
</ul>
</div>
<br>
<label for="tax-by">Shipping tax class:</label>
<div
  class="btn-group dropdown" 
  appDropdown >
  <button
  type="button"
  class="btn dropdown-toggle">
    Shipping tax class...
    <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
  </button>
<ul class="dropdown-menu">
  <li class="dropdown-item">Shipping tax based on basket items</li>
  <li class="dropdown-item">Standard rate</li>
  <li class="dropdown-item">Reduced rate</li>
  <li class="dropdown-item">Zero rate</li>
</ul>
</div>
<br>
<label for="tax-rounding">Round tax:</label>
<input type="radio" class="form-check-inline"> Rounding at subtotal, rather than per line.
<br>
<label for="display-tax">Display prices in the shop including tax:</label>
<div
  class="btn-group dropdown" 
  appDropdown >
  <button
  type="button"
  class="btn dropdown-toggle">
    Display including tax?
    <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
  </button>
<ul class="dropdown-menu">
  <li class="dropdown-item">Including TAX (VAT)</li>
  <li class="dropdown-item">Excluding TAX (VAT)</li>
</ul>
</div>
<br>
<label for="display-tax">Display tax totals:</label>
<div
  class="btn-group dropdown" 
  appDropdown >
  <button
  type="button"
  class="btn dropdown-toggle">
   Display tax totals...
    <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="quantity"></span>
  </button>
<ul class="dropdown-menu">
  <li class="dropdown-item">As a single total</li>
  <li class="dropdown-item">Itemized</li>
</ul>
</div>