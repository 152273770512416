<div class="container">
  <h1>Acceptable Use Policy</h1>
  <p> <mat-hint>
    <i>Last Updated 17 August 2020 </i>
</mat-hint>
  </p>
  <p>
    This Acceptable Usage Policy covers the security and use of all
    LyricalDays.com’s user sessions.
  </p>

  <h3>User Account Access Control</h3>
  <p>
    Access to the LyricalDays.com website and/or webapp is controlled by the use
    of User IDs, passwords and/or tokens. All User IDs and passwords are to be
    uniquely assigned to named individuals and consequently, individuals are
    accountable for all actions on the LyricalDays.com website and/or webapp.
  </p>

  <p>
    Individuals must not:
  </p>
  <ul>
    <li>
      Allow anyone else to use their user ID/token and password on any
      LyricalDays.com website and/or webapp.
    </li>
    <li>
      Leave their user accounts logged in at an unattended and unlocked
      computer.
    </li>
    <li>
      Use someone else’s user ID and password to access LyricalDays.com’s
      website and/or webapp.
    </li>
    <li>
      Leave their password unprotected (for example writing it down).
    </li>
    <li>
      Perform any unauthorised changes to LyricalDays.com's website and/or
      webapp, its databases or information.
    </li>
    <li>
      Attempt to access data that they are not authorised to use or access.
    </li>
    <li>
      Exceed the limits of their authorisation or specific business need to
      interrogate the system or data.
    </li>
    <li>
      Connect any non-LyricalDays.com's authorised device to the
      LyricalDays.com's network or website and/or webapp.
    </li>
    <li>
      Store LyricalDays.com's data on any non-authorised LyricalDays.com
      equipment.
    </li>
    <li>
      Give or transfer LyricalDays.com data or software to any person or
      organisation. outside LyricalDays.com without the authority of
      LyricalDays.com.
    </li>
  </ul>

  <h3>Using LyricalDays.com Email Service</h3>
  <p>
    All individuals are accountable for their actions and use of
    LyricalDays.com's website and/or webapp and it's emailing service.
  </p>
  <p>
    Individuals must not:
  </p>
  <ul>
    <li>
       Use the LyricalDays.com's website and/or webapp for the purposes of
      harassment or abuse.
    </li>
    <li>
    Use LyricalDays.com website and/or webapp for the purposes of incitement to violence, or otherwise conduct communication to cause criminal harm or damage.
    </li>
    <li>
    Use LyricalDays.com website and/or webapp and its services to distribute spam or chain letters.
    </li>
    <li>
      Use LyricalDays.com website and/or webapp and its services to distribute unprotected, sensitive or confidential information.
    </li>
    <li>
      Use LyricalDays.com to solicit data or information from others under false pretenses.
    </li>
    <li>
      Masquerade as LyricalDays.com to (a) conduct any kind of business, (b) conduct any kind of fradualent or other unlawful activity.
    </li>
    <li>
       In any way infringe any copyright, database rights, trademarks or other
      intellectual property.
    </li>
  </ul>
</div>
