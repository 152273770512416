<h5>Payment Details</h5>
<div class="row">
  <div class="col-sm-12">
  <form [formGroup]="paymentDetailsForm">
    <div class="form-group">
        <label for="card-type">Card Type</label>
        <input type="text" id="card-type" class="form-control" formControlName="card-type">
        <span 
        *ngIf="!paymentDetailsForm.get('card-type').valid && paymentDetailsForm.get('card-type').touched"
        class="form-text text-danger">Please input your card type.</span>
        <label for="cardholder-name">Cardholder Name</label>
        <input type="text" class="form-control" formControlName="cardholder-name">
        <span 
        *ngIf="!paymentDetailsForm.get('cardholder-name').valid && paymentDetailsForm.get('cardholder-name').touched"
        class="form-text text-danger">Please input the name of the cardholder.</span>
        <label for="card-number">Card Number</label>
        <input type="text" pattern="[0-9]*" placeholder="4444 4444 4444 4444" class="form-control" formControlName="card-number">
        <span 
        *ngIf="!paymentDetailsForm.get('card-number').valid && paymentDetailsForm.get('card-number').touched"
        class="form-text text-danger">Please input your card number.</span>
        
          <label for="card-expiration">Card Expiration</label>
          <input type="text" placeholder="MM/YYYY" class="form-control" formControlName="card-expiration" maxlength="6" size="6" col="6">
          <span 
          *ngIf="!paymentDetailsForm.get('card-expiration').valid && paymentDetailsForm.get('card-expiration').touched"
          class="form-text text-danger">Please input details of a current card.</span>
          <label for="card-security-code">Card Security Code</label>
          <input type="text" pattern="[0-9]*" class="form-control" formControlName="card-security-code" maxlength="4" size="6" col="6">
          <span 
          *ngIf="!paymentDetailsForm.get('card-security-code').valid && paymentDetailsForm.get('card-security-code').touched"
          class="form-text text-danger">Please input your card CVV as printed on the signature strip of your card.</span>
      
    </div>
  </form>
  </div>
  </div>