<h4 class="text-center">
  Occasions
</h4>
<p>
  This is a robust list to be added to as we have products to sell. For example: Christmas, Easter and birthdays.
</p>
<form [formGroup]="occForm" (ngSubmit)="onOcc()">
<div class="form-inline">
  <label for="addTag" class="sr-only"> {{ editMode ? 'Update Occasion' : 'Add Occasion' }}</label>
<input type="text" class="form-control ml-1" formControlName="occControl">
<button class="btn btn-primary ml-1" type="submit" [disabled]="!occForm.valid">
  {{ editMode ? 'Update' : 'Add' }}
</button>
<span *ngIf="editMode">
  <button class="btn btn-warning ml-1" type="button" (click)="onCancelEdit()">
    Cancel
</button>
<button class="btn btn-danger ml-1" type="button" (click)="onDelete()">
  Delete
</button>
</span>
</div>
</form>
<hr>

<div>
  <p>New Occ</p>
  <p>{{ occForm.get('occControl').value }}</p>
</div>

<span *ngFor="let occasion of (occasion$ | async);">
  <button class="btn btn-outline-primary m-2" (click)="onEdit(occasion.id, occasion.name)">
  {{ occasion.name }}
</button>
</span>

