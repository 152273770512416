<!-- <table class="table-bordered col-sm-12">
  <tr class="table">
    <th class="">Name</th>
    <th>ID/SKU</th>
    <th>Stock</th>
    <th>Price</th>
    <th>Categories</th>
    <th>Tags</th>
    <th>Featured</th>
    <th>Date Scheduled/Published</th>
    <th>Stats (Report)</th>
  </tr>
  <tr class="table" *ngFor="let ecard of ecards">
    <td>{{ ecard.name }}</td>
    <td>{{ ecard.id }}</td>
    <td>{{ "stockLevel" }}</td>
    <td>{{ ecard.price | currency: "GBP":"symbol" }}</td>
    <td>{{ ecard.category }}</td>
    <td>{{ ecard.tag }}</td>
    <td>//Featured toggle</td>
    <td>{{ ecard.dateAdded }} //Published or scheduled</td>
    <td>// Go to report on this ecard</td>
  </tr>
</table> -->

<section>
  <div fxLayout="row" fxLayoutAlign="center">
    <h1>
      ecard Management Directory
    </h1>
  </div>
</section>

<section>
  <div fxLayoutAlign="center center">
    <mat-form-field fxFlex="40%">
      <span matPrefix>
        <mat-icon>search</mat-icon>
      </span>
      <input
        matInput
        type="text"
        (keyup)="doFilter($event.target.value)"
        placeholder="Filter..."
      />
    </mat-form-field>
  </div>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a [routerLink]="[element.id, 'edit']">
          {{ element.name }}</a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >ID</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a [routerLink]="[element.id, 'edit']">{{ element.id }}</a ></mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="thumbnail">
      <mat-header-cell *matHeaderCellDef mat-sort-heade>Thumbnail</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <img [src]="element.thumbnail.downloadURL" class="img-fluid" alt=""></mat-cell>
    </ng-container>

    <ng-container matColumnDef="releaseDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Release Date</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.releaseDate | date }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="price">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Price</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.price | currency: currency.toUpperCase():"symbol" }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="occasions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Occasions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngFor="let occ of element.occasions, let i = index">{{ occ.name }}<span *ngIf="element.occasions.length != (i+1)">{{ ",  " + " " }}</span></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="categories">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Categories</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.categories }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.tags }}</mat-cell>
      </ng-container>

    <ng-container matColumnDef="stats">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Stats</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ "stats" }}</mat-cell>
      </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator
    fxLayoutAlign="center center"
    #paginator
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]"
  >
  </mat-paginator>
</section>

