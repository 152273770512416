<div class="dropzone" 
     appDropZone
     (hovered)="toggleHover($event)"
     (dropped)="onDrop($event)"
     [class.hovering]="isHovering">


     <h3>AngularFire Drop Zone</h3>
     <p>Drag and Drop a File</p>
     <div class="file">
      <label class="file-label">
        <input
          class="file-input"
          type="file"
          (change)="onDrop($event.target.files)"
        />
  
        <span class="file-cta">
          <span class="file-icon">
            <mat-icon>cloud_upload</mat-icon>
          </span>
          <span class="file-label">
            or choose a file...
          </span>
        </span>
      </label>
    </div>
</div>



<h3>Uploads</h3>

<div *ngFor="let file of files">
  <app-upload-task [file]="file"></app-upload-task>
</div>

<!-- <h4 align="center">
  Add New Media Item
</h4>
<div
  class="dropzone"
  appDropZone
  (hovered)="toggleHover($event)"
  (dropped)="startUpload($event)"
  [class.hovering]="isHovering"
>
  <h3>Angular Fire Drop Zone</h3>

  <div class="file">
    <label class="file-label">
      <input
        class="file-input"
        type="file"
        (change)="startUpload($event.target.files)"
      />

      <span class="file-cta">
        <span class="file-icon">
          <mat-icon>cloud_upload</mat-icon>
        </span>
        <span class="file-label">
          or choose a file...
        </span>
      </span>
    </label>
  </div>
</div>

<div *ngIf="percentage$ | async as pct">
  <progress class="progress is-info" 
[value]="pct" 
max="100">
</progress>

  <mat-progress-bar mode="determinate" [value]="pct"></mat-progress-bar>

  {{ pct | number }}%
</div>

<div *ngIf="snapshot$ | async as snap">
  {{ snap.bytesTransferred | fileSize }} of {{ snap.totalBytes | fileSize }}
  {{ snap.bytesTransferred }} of {{ snap.totalBytes }}

  <div *ngIf="downloadURL | async as url">
    <h3>Results! Blog Post Image</h3>
    <img [src]="url" /><br />
    <a [href]="url" target="_blank" rel="noopener">Download me!</a>
  </div>

  <button
    mat-button
    color="accent"
    type="button"
    (click)="task.pause()"
    [disabled]="!isActive(snap)"
  >
    Pause
  </button>
  <button
    mat-button
    color="warn"
    type="button"
    (click)="task.cancel()"
    [disabled]="!isActive(snap)"
  >
    Cancel
  </button>
  <button
    mat-button
    color="primary"
    type="button"
    (click)="task.resume()"
    [disabled]="!(snap?.state === 'paused')"
  >
    Resume
  </button>
</div>

<label for="alt-text">Please set the alt text: </label>
<input type="text" placeholder="alt-text" /> -->
