  <a 
style="cursor: pointer;"
[routerLink]="[index]"
routerLinkActive="active"
class="list-group-item clearfix">
      <div class="row">
          <div class="col-3 text-center">
              {{ user.username }}
          </div>
          <div class="col-3 text-center">
              {{ user.firstname }} {{ user.surname }}
          </div>
          <div class="col-3 text-center">
                {{ user.email }}
          </div>
          <div class="col-3 text-center">
                  {{ user.roles }}
          </div>
      </div>
</a>


