<div class="row">

  <div class="col-xs-12 col-md-4">
    <video></video>
    <img [src]="ecard.thumbnail" alt="{{ecard.name}}"
      class="figure-img img-fluid">
      </div>
<div class="col-xs-12 col-md-7 offset-md-1">
    <h3>{{ ecard.name }}</h3>
  <h5>Customise:</h5>
  <form [formGroup]="sendECard" (ngSubmit)="onSendECard()">
    <div class="row">
      <label for="recipient(s)">Send to:</label>
    <input formControlName="recipient(s)" class="form-control">
    <button type="button" class="btn btn-primary">+</button>
    </div>
    <div class="row">
      <label for="message">Message</label>
    <textarea class="form-control"></textarea>
    </div>
    <!-- <div class="row">
      <label for="schedule">Schedule this?</label>
      <app-calendar></app-calendar>
    </div> -->
  </form>
</div>
</div>