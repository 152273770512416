<h2 mat-dialog-title align='center'>Addressbook</h2>
<mat-dialog-content>
<section *ngIf="addNewContact">
      <div class="text-center">
            <h3>{{ editMode ? 'Update Contact' : ' Quick Add Contact' }}</h3>
        </div> 
        <div class="row">
            <div class="col-12">
        
        <form [formGroup]="contactForm" (ngSubmit)="onQuickAddContact()">
            <div class="form-group row">
                <div class="col">
                    <input type="text" inputmode="latin-name" placeholder="First name" class="form-control" formControlName="contactFirstName" required>
                </div>
                    <div class="col">
                        <input type="text" inputmode="latin-name" placeholder="Surname" class="form-control" formControlName="contactSurname" required>
                    </div>
                    
            </div>
          <div class="form-group row">
              
          </div>
          
          <!-- <div class="form-group row">
              <div class="col">
                   <input type="text" inputmode="latin-name" placeholder="Nickname" class="form-control" formControlName="contactNickname">
              </div>
               <div class="col">
                          <div 
                class="btn-group dropdown" 
                appDropdown>
                  <button 
                  type="button" 
                   class="btn btn-secondary dropdown-toggle">
                    {{ relationship }}
                    <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="relationship"></span>
                   </button>
                       <ul class="dropdown-menu">
                       <li *ngFor="let relationship of relationSelector" (click)="updateRelationship(relationship)">
                        <a class="dropdown-item">{{ relationship }}</a>
                       </li>
                       </ul>
                 </div>    
               </div>
            </div> -->
          <!-- <div class="form-group row">
              <div class="col">
                   <input type="text" inputmode="tel" placeholder="Phone number" class="form-control" formControlName="contactMobileNumber">
              </div>
          </div> -->
          <div class="form-group row">
              <div class="col">
                   <input type="email" inputmode="email" placeholder="Email Address" class="form-control" formControlName="contactEmail">
                   <span 
                    *ngIf="!contactForm.get('contactEmail').valid && contactForm.get('contactEmail').touched"
                    class="form-text text-danger">Please enter a valid email address.</span>
              </div>
          </div>
          <!-- <div class="text-center">
              <h4>Important Dates</h4>
          </div>
          <div class="form-group row">
              <div class="col-12">
                    <label for="birthday">Birthday</label>
                <input type="date" inputmode="date" placeholder="Date of birth" class="form-control" formControlName="contactBirthday">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="important-dates" class="col-form-label mx-2">Other Important Dates</label>
                         <div 
                         class="btn-group dropdown" 
                         appDropdown>
                           <button 
                           type="button" 
                            class="btn btn-secondary dropdown-toggle row">
                             {{ occasion }}
                             <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="important-dates"></span>
                            </button>
                                <ul class="dropdown-menu">
                                <li *ngFor="let occasion of occasionSelector" (click)="addOccasion(occasion)">
                                 <a class="dropdown-item">{{ occasion }}</a>
                                </li>
                                <span *ngIf="addCustomOccasion">
                                <li *ngFor="let custom of customOccasionSelector" (click)="addOccasion(custom)">
                                    <a class="dropdown-item">{{ custom }}</a> 
                                </li>
                                </span>
                                
                                </ul>
                          </div>           
                    </div>
                        
              </div>
                     <span *ngIf="addCustomOccasion">
                            <div class="row">
                                        <input type="text" inputmode="latin-name" placeholder="Custom Occasion" #customRef class="form-control col-10">
                                         <button type="button" class="btn btn-primary mx-1" (click)="onAddCustomOccasion(customRef)">Add</button>
                                    
                            </div>
                        </span> 
                        <span *ngIf="addDate">
                                <div class="row">
                                        <label for="{{ occasion || custom }}">{{occasion || custom }}</label>
                                        <input type="date" inputmode="date" placeholder="Occasion Date" class="form-control">
                                </div>
                            </span> 
              </div>
             
            </div>  -->
        <button class="btn btn-danger float-right" type="button" (click)="onCancelQuickAdd()"> X </button> 
         <button class="btn btn-primary float-right" [disabled]="!contactForm.valid"> {{ editMode ? 'Update Contact' : 'Add Contact' }}</button> 
        
         <!-- submit to function at top of form -->
        </form>
        </div>
        </div>
        
        
</section>
<section *ngIf="!addNewContact">

    <p *ngIf="!asDIY">Please select up to {{ passedData.recipientCount }} recipients. </p>
    <p *ngIf="asDIY">Please select someone to send this card to.</p>
    <!-- <ul class="list-group">
        <li *ngFor="let address of addressbook" 
        (click)="onSelected(address)"
        class="list-group-item" [ngClass]="{ selected: address.selected == true }">
            <h6>{{ address.contactFirstName}} {{ address.contactSurname }}</h6>
            <ul class="list-unstyled">
                <li class="">+44 {{ address.contactMobileNumber }}</li>
                <li class="">{{ address.contactEmail }}</li>
            </ul>
    </li>
    </ul> -->
<p *ngIf="!asDIY">You have {{ passedData.recipientCount - recipients.length }} cards left in the pack.</p>
<!-- <p>Currently these cannot be saved for later.</p> -->

<div fxLayoutAlign="center center">
    <mat-form-field fxFlex="40%">
        <span matPrefix>
            <mat-icon>search</mat-icon>
        </span>
    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter...">
  </mat-form-field> 
  <div class="col-2">
      <button type="button" 
      class="btn btn-primary float-right"
      (click)="addNewContact = true;">
      Add Contact</button>
    </div>
  </div>
  
  
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="contactFirstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="onSelected(element)" [ngClass]="{ selected: element.selected == true }">
        {{ element.contactFirstName }} {{ element.contactSurname }}</mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="contactSurname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Surname</mat-header-cell>
        <mat-cell *matCellDef="let element"><a [routerLink]="[element.id, 'edit']">
          </a></mat-cell>
      </ng-container> -->
  
    <!-- <ng-container matColumnDef="contactMobileNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile Number</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onSelected(element)" [ngClass]="{ selected: element.selected == true }">
            {{ element.contactMobileNumber }}</mat-cell>
    </ng-container> -->
  
    <ng-container matColumnDef="contactEmail">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="onSelected(element)" [ngClass]="{ selected: element.selected == true }">
          {{ element.contactEmail }}</mat-cell>
    </ng-container>
  
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  
  <mat-paginator fxLayoutAlign="center center" #paginator
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</section>


</mat-dialog-content>

<mat-dialog-actions *ngIf="!addNewContact">
    <button
    mat-button
    [disabled]="onContact()"
    [mat-dialog-close]="recipients">
        Done!
    </button>
    <button
    mat-button (click)="onNoClick()">
        Cancel
    </button>
</mat-dialog-actions>