<h3 class="text-center">Manage Occasions, Categories and Tags</h3>
<hr>
<div class="row">
  <div class="col-sm-4">
    <ul class="list-group">
      <li class="list-group-item" 
      routerLink="occasions"
      style="cursor:pointer;">Occasions</li>
      <li class="list-group-item" 
      routerLink="categories"
      style="cursor:pointer;">Categories</li>
      <li class="list-group-item" 
      routerLink="tags"
      style="cursor:pointer;">Tags</li>
    </ul>
</div>
<div class="col-sm-8">
  <router-outlet></router-outlet>
</div>
</div>



