<div class="container">
  <section class="m-3">
  <div class="col-12 col-md-6 offset-md-3">
  <h2>Contact Us</h2>
  <p>Hello there!</p>
  <p>
    If you are having an issue with our site or its products, have a question,
    or otherwise need to contact us, please send us an email to:
  </p>
  <p>admin@lyricaldays.com</p>
  <p>
    We will do our best to get back to you as soon as we can. Thank you for your
    patience.
  </p>
</div>
</section>
</div>

