<footer class="nav navbar nav-fill">

<div class="w-100 align-center">
        <div class="row m-0">
            <div class="col-12">
                 <app-footer-nav></app-footer-nav>
            </div>
        </div>

<div class="w-100 align-center">

        <div class="col-12">
            <div class="row m-0">
            <!-- <p class="my-auto mr-1">Currency: </p> -->
            <div 
            class="btn-group dropdown mx-auto" 
            appDropdown>
            <!-- <label class="align-middle m3-5">Currency: </label> -->
            <button 
            type="button" 
            class="btn btn-secondary dropdown-toggle rounded-pill" aria-label="Select currency">    
                <span class="text-center">
                    <span class="my-auto currency-symbol">
                      <span *ngIf="currency == 'gbp'" (click)="onUpdateCurrency('gbp')" class="fi fi-gb my-auto currency-symbol"></span>
                      <span *ngIf="currency == 'usd'" (click)="onUpdateCurrency('usd')" class="fi fi-us my-auto currency-symbol"></span>
                      <span *ngIf="currency == 'eur'" (click)="onUpdateCurrency('eur')" class="fi fi-eu my-auto currency-symbol"></span>
                  </span>
                </span>
                {{ currency | uppercase }} {{ currency == 'gbp' ? '(£)': '' || currency == 'usd' ? '($)' : '' || currency == 'eur' ? '(€)' : ''}}     
                <span class="glyphicon glyphicon-caret" aria-hidden="true" aria-label="select currency"></span>
            </button>
            <ul class="dropdown-menu">
                <li *ngFor="let currency of currencyArray" (click)="onUpdateCurrency(currency)" class="qty"><a class="dropdown-item">
                    <span class="text-center">
                        <span class="my-auto currency-symbol">
                          <span *ngIf="currency == 'gbp'" (click)="onUpdateCurrency('gbp')" class="fi fi-gb my-auto currency-symbol"></span>
                          <span *ngIf="currency == 'usd'" (click)="onUpdateCurrency('usd')" class="fi fi-us my-auto currency-symbol"></span>
                          <span *ngIf="currency == 'eur'" (click)="onUpdateCurrency('eur')" class="fi fi-eu my-auto currency-symbol"></span>
                      </span>
                      </span>
                    {{currency | uppercase }} {{ currency == 'gbp' ? '(£)': '' || currency == 'usd' ? '($)' : '' || currency == 'eur' ? '(€)' : ''}} </a></li>
            </ul>
            </div>
        </div>
        </div>

</div>
    <div class="row mt-5">
        <div class="col-12 col-md-10 offset-md-1 centerblock align-content-center">
            <div class="align-self-center col-md-6 offset-md-3">
                    <p class="copyright text-center">©2019-2025 LyricalDays.com. All rights reserved.</p>
                    <p class="copyright text-center">Beta Version 1.2.9</p>
            </div>
        </div>

      

    </div>
</div>

</footer>