    <div class="row">
    <div class="col-sm-3 col-md-2 column text-center m-2">

        <a routerLink="/admin">
            <img src="../assets/brand/lyrical-days-logo.png" alt="Lyrical Days" class="img-fluid mt-5 mb-5">
        </a>

            <button routerLink="shop-management/tax"
            role="button" 
            class="btn btn-shop-m">
            <img src="../assets/brand/lyrical-days-icons_TAX.png" 
            alt="TAX" class="img-fluid">
            <br>
            <p class="sr-only">TAX</p>
            </button>
            
            <button routerLink="shop-management/shipping"
            role="button" 
            class="btn btn-shop-m">
            <img src="../assets/brand/lyrical-days-icons_Shipping.png" 
            alt="SHIPPING" class="img-fluid">
            <br>
            <p class="sr-only">SHIPPING</p>
            </button>
            
            <button routerLink="shop-management/coupons"
            role="button" 
            class="btn btn-shop-m">
            <img src="../assets/brand/lyrical-days-icons_Coupons.png" 
            alt="COUPONS" class="img-fluid">
            <br>
            <p class="sr-only">COUPONS & VOUCHERS</p> 
            </button>
            
            
            <button routerLink="shop-management/email-templates"
            role="button" 
            class="btn btn-shop-m">
            <img src="../assets/brand/lyrical-days-icons_Email Notifications.png" 
            alt="EMAIL NOTIFACTIONS" class="img-fluid">
            <br>
            <p class="sr-only">EMAIL NOTIFACTION templates</p> 
            </button>
            
            <button routerLink="shop-management/reports"
            role="button" 
            class="btn btn-shop-m">
            <img src="../assets/brand/lyrical-days-icons_Reports.png" 
            alt="REPORTS" class="img-fluid">
            <br>
            <p class="sr-only">REPORTS</p> 
            </button>
            
            
            <button routerLink="product-management/products"
            role="button" 
            class="btn btn-product-m">
            <p class="sr-only">Product Directory</p>
            </button>
            
            <button routerLink="product-management/orders"
            role="button" 
            class="btn btn-product-m">
            <p class="sr-only">Order Directory</p>
            </button>
            
            <button routerLink="product-management/manage-occ-cat-tag"
            role="button" 
            class="btn btn-product-m">
            <p class="sr-only">Manage Occasions, Cateogries & Tags</p>
            </button>
            
            
            <button 
            role="button" 
            class="btn btn-primary col">
            <p class="sr-only">Help Desk</p> 
            </button>
            
            <button routerLink="customer-support/users"
            role="button" 
            class="btn btn-primary col">
            <p class="sr-only">Users</p> 
            </button>
            
            <br>

            <button class="btn btn-primary" type="button" (click)="onAddChristmasTreetoDaddysaccount()">
                add christmas tree ecard to daddy's account
            </button>
            
                
    </div>
    <div class="col">

                <router-outlet></router-outlet>

    </div>
</div>


